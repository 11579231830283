import { useState } from "react";
import styled from "styled-components";
import { hslToHex } from "util/color";

import { ColorGrid } from "./color-grid";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 3rem;
`;
const Label = styled.div`
  width: 30%;
  font-size: 1rem;
  padding: 0 1rem;
`;
const IndicatorContainer = styled.div`
  border-radius: 5px;
  height: 3rem;
  flex-grow: 1;
  padding: 0px 1rem;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  cursor: pointer;
`;
const GridContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
`;

type ColorSliderProps = {
  value?: string;
  label: string;
  onChange: (hue: string) => void;
};

export const ColorSlider = ({
  value = hslToHex(Math.floor(Math.random() * 360), 100, 50),
  label,
  onChange,
}: ColorSliderProps) => {
  const [showGrid, setShowGrid] = useState(false);
  const [color, setColor] = useState<string>(value);

  const handleChangeClick = () => {
    setShowGrid(!showGrid);
  };

  const handleColorChange = (color: string) => {
    setColor(color);
    onChange(color);
  };

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <IndicatorContainer
        style={{ backgroundColor: color }}
        onClick={handleChangeClick}
      >
        Click to change
        {showGrid && (
          <GridContainer>
            <ColorGrid onChange={handleColorChange} selectedColor={color} />
          </GridContainer>
        )}
      </IndicatorContainer>
    </Container>
  );
};

import { Balance } from "api/types/Balances";
import { MediaQueries } from "constants/responsive";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { hslToHex } from "util/color";
import { formatCurrency } from "util/numbers";

const Container = styled.div`
  display: table;
  width: 100%;
  user-select: none;
  font-size: 1rem;
  ${MediaQueries.Mobile} {
    font-size: 0.6rem;
  }
`;
const Name = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding: 0 0.5rem;
  min-width: 5rem;
`;
const Total = styled.div<{ color?: string; background?: string }>`
  background-color: white;
  padding: 0 1rem;
  text-align: right;
  ${({ color }) => (color ? `color: ${color}` : "color:grey")};
  width: calc(100% / 3);
  ${MediaQueries.Mobile} {
    padding: 0 0.2rem;
  }
`;
const TotalHeader = styled(Total)`
  text-align: right;
  height: 40px;
  vertical-align: middle;
  font-weight: bold;
  /* padding: 0px 0.2rem; */
`;
const InSpan = styled.span`
  padding: 0px 0.4rem;
  border-radius: 10px;
  background-color: ${hslToHex(90, 100, 85)};
  color: black;
`;
const OutSpan = styled.span`
  padding: 0px 0.4rem;
  border-radius: 10px;
  background-color: ${hslToHex(0, 100, 85)};
  color: black;
`;
const TotalSpan = styled.span`
  padding: 0px 0.4rem;
  border-radius: 10px;
`;
const ItemContainer = styled.div`
  display: table-row;
  div {
    display: table-cell;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  &:first-of-type {
    ${Total}:first-of-type {
      background-color: red;
    }
  }
  &:first-of-type {
    div:nth-of-type(2) {
      border-radius: 0.5rem 0 0 0;
    }
    div:nth-of-type(4) {
      border-radius: 0 0.5rem 0 0;
    }
  }
  &:last-of-type {
    border-bottom: 0;
    div:nth-of-type(2) {
      border-radius: 0 0 0 0.5rem;
    }
    div:nth-of-type(4) {
      border-radius: 0 0 0.5rem 0;
    }
  }
`;

const getColor = (plus: number, minus: number) => {
  if (plus + minus < 0) {
    return "red";
  } else if (plus + minus > 0) {
    return "green";
  } else {
    return "grey";
  }
};

const getYearTotal = (currentYear: number, balance?: Balance) => {
  if (!balance) {
    return 0;
  }
  return Object.keys(balance.years).reduce((total, year) => {
    if (currentYear >= parseInt(year)) {
      total += balance.years[year].total;
    }

    return total;
  }, 0);
};

type OverViewItem = {
  id: number;
  name: string;
  color?: string;
};

type OverviewTableProps = {
  items: OverViewItem[];
  balances: { [accountId: string]: Balance };
  onClick: (id: number) => void;
  year: number;
};

type TogglableTotalProps = {
  inAmount: number;
  outAmount: number;
  total: number;
};

const TogglableTotal = ({
  inAmount,
  outAmount,
  total,
}: TogglableTotalProps) => {
  const [toggle, setToggle] = useState<number>(0);

  const handleToggle = useCallback(() => {
    setToggle((x) => (x + 1) % 3);
  }, [setToggle]);

  if (toggle === 1) {
    return (
      <Total color={"black"} onClick={handleToggle}>
        <OutSpan>{formatCurrency(outAmount || 0)}</OutSpan>
      </Total>
    );
  }
  if (toggle === 2) {
    return (
      <Total color={"black"} onClick={handleToggle}>
        <InSpan>{formatCurrency(inAmount || 0)}</InSpan>
      </Total>
    );
  }
  return (
    <Total color={getColor(inAmount, outAmount)} onClick={handleToggle}>
      <TotalSpan>{formatCurrency(total || 0)}</TotalSpan>
    </Total>
  );
};

export const OverviewTable = ({
  items,
  balances,
  onClick,
  year,
}: OverviewTableProps) => {
  const totals = useMemo(() => {
    return items.reduce(
      (sum, item) => {
        sum[0] += balances[item.id]?.month.in || 0;
        sum[1] += balances[item.id]?.month.out || 0;
        sum[2] += balances[item.id]?.month.total || 0;
        sum[3] += balances[item.id]?.ytd.total || 0;
        sum[4] += balances[item.id]?.total || 0;
        return sum;
      },
      [0, 0, 0, 0, 0]
    );
  }, [balances, items]);

  return (
    <Container>
      <ItemContainer>
        <Name />
        <TotalHeader>Month</TotalHeader>
        <TotalHeader>Year</TotalHeader>
        <TotalHeader>Balance</TotalHeader>
      </ItemContainer>
      {items.map((account) => {
        const { id, name } = account;
        return (
          <ItemContainer key={`account-${id}`}>
            <Name onClick={() => onClick(id)}>{name}</Name>
            <TogglableTotal
              inAmount={balances[id]?.month.in}
              outAmount={balances[id]?.month.out}
              total={balances[id]?.month.total}
            />
            <TogglableTotal
              inAmount={balances[id]?.ytd.in}
              outAmount={balances[id]?.ytd.out}
              total={balances[id]?.ytd.total}
            />
            <Total>
              <TotalSpan>
                {formatCurrency(getYearTotal(year, balances[id]) || 0)}
              </TotalSpan>
            </Total>
          </ItemContainer>
        );
      })}
      <ItemContainer>
        <Name />
        <TotalHeader>
          <TogglableTotal
            inAmount={totals[0]}
            outAmount={totals[1]}
            total={totals[2]}
          />
        </TotalHeader>
        <TotalHeader>{formatCurrency(totals[3])}</TotalHeader>
        <TotalHeader>{formatCurrency(totals[4])}</TotalHeader>
      </ItemContainer>
    </Container>
  );
};

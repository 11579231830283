import { makeAutoObservable } from "mobx";

class MainInterface {
  public swipeIndex: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setSwipeIndex = (index: number) => {
    this.swipeIndex = index;
  };
}

export class LayoutStore {
  mainInterface: MainInterface;

  constructor() {
    this.mainInterface = new MainInterface();
    makeAutoObservable(this);
  }
}

import styled from "styled-components";
import { BiCheck, BiX, BiPlus } from "react-icons/bi";

import {
  ButtonType,
  FlexButton,
  FlexButtonContainer,
} from "components/ui/forms";
import { ReactNode } from "react";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const ChildrenContainer = styled.div`
  height: 100%;
  margin-bottom: -64px;
  padding-bottom: 84px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: 0;
`;

type ModalActionsWrapperProps = {
  children: ReactNode;
  onAccept?: () => void;
  acceptIsDisabled?: boolean;
  onCancel: () => void;
  onCreate?: () => void;
};

export const ModalActionsWrapper = ({
  children,
  onAccept,
  acceptIsDisabled,
  onCancel,
  onCreate,
}: ModalActionsWrapperProps) => {
  return (
    <Container>
      <ChildrenContainer>{children}</ChildrenContainer>
      <FlexButtonContainer>
        {onCancel && (
          <FlexButton type={ButtonType.Cancel} onClick={onCancel}>
            <BiX />
          </FlexButton>
        )}
        {onAccept && (
          <FlexButton
            type={ButtonType.Accept}
            onClick={onAccept}
            disabled={acceptIsDisabled}
          >
            <BiCheck />
          </FlexButton>
        )}
        {onCreate && (
          <FlexButton type={ButtonType.Create} onClick={onCreate}>
            <BiPlus />
          </FlexButton>
        )}
      </FlexButtonContainer>
    </Container>
  );
};

import { BiCheck, BiX } from "react-icons/bi";
import styled from "styled-components";
import * as yup from "yup";

import { formatCurrency } from "util/numbers";

import {
  DisplayInput,
  FieldContainer,
  FlexButton,
  FlexButtonContainer,
  TextInput,
} from "components/ui/forms";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const validation = yup.object().shape({
  name: yup.string().required(),
});

const SubjectForm = ({
  data = {},
  onBudgetClick,
  onNameChange,
  onSubmit,
  onCancel,
}) => {
  const handleSubmit = () => {
    try {
      const validatedData = validation.validateSync(data, {
        abortEarly: false,
      });
      onSubmit(validatedData);
    } catch (errors) {
      console.log(errors);
      // setErrors(getKeyedValidationErrors(errors))
    }
  };

  const canSubmit = () => {
    try {
      validation.validateSync(data);
    } catch (errors) {
      return false;
    }
    return true;
  };

  const { name, budget } = data;

  return (
    <Container>
      <div>
        <FieldContainer>
          <TextInput
            label="Name ..."
            placeholder="Name ..."
            value={name}
            onChange={onNameChange}
          />
        </FieldContainer>

        <FieldContainer>
          <DisplayInput
            label="Monthly budget"
            placeholder=""
            onClick={onBudgetClick}
            value={budget || 0}
            display={formatCurrency(budget || 0)}
          />
        </FieldContainer>
      </div>

      <FlexButtonContainer>
        <FlexButton onClick={onCancel} type="cancel">
          <BiX />
        </FlexButton>
        <FlexButton
          onClick={handleSubmit}
          disabled={!canSubmit()}
          type="accept"
        >
          <BiCheck />
        </FlexButton>
      </FlexButtonContainer>
    </Container>
  );
};

export default SubjectForm;

import { BrowserRouter as Router } from "react-router-dom";

import { useStore } from "hooks";
import AuthContainer from "containers/auth";
import Interface from "./containers/interface";

function App() {
  const { isReady } = useStore();

  if (!isReady) {
    return <div>loading...</div>;
  }
  return (
    <Router>
      <AuthContainer>
        <Interface />
      </AuthContainer>
    </Router>
  );
}

export default App;

import { useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BiCaretLeft, BiCaretRight } from "react-icons/bi";

import { useStore } from "hooks";
import {
  IconButton,
  IconSize,
  IconType,
} from "components/ui/icon-button/IconButton";
import { HeaderSmall } from "components/ui/Typography";
import { dateObjectToString } from "util/date";
import { MediaQueries } from "constants/responsive";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
`;
const ButtonContainer = styled.div<{ right?: boolean }>`
  width: 30%;
  display: flex;
  flex-direction: row;
  ${(props) => (props.right ? `justify-content: flex-end;` : "")}
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  & div {
    margin: 0 0.5rem;
    ${MediaQueries.Mobile} {
      margin: 0 0.2rem;
    }
  }
`;
const MobileButtons = styled.span`
  display: none;
  @media screen and (max-width: 1000px) {
    display: block;
  }
`;
const YearContainer = styled.div`
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  ${HeaderSmall} {
    margin: 0;
  }
  & svg {
    margin: 0 1rem;
    cursor: pointer;
    ${MediaQueries.Mobile} {
      margin: 0 0.2rem;
    }
  }
`;

type DashboardHeaderProps = {
  year: number;
  onYearChange: (year: number) => void;
  onStatsClick?: () => void;
  onListClick?: () => void;
};

const DashboardHeader = ({
  year,
  onYearChange,
  onStatsClick,
  onListClick,
}: DashboardHeaderProps) => {
  const { profileStore } = useStore();

  const today = useMemo(() => {
    const todayInYear = new Date();
    todayInYear.setFullYear(year);
    return dateObjectToString(todayInYear);
    // return `${todayInYear.getFullYear()}-${todayInYear.getMonth()}-${todayInYear.getDate()}`;
  }, [year]);

  return (
    <Container>
      <ButtonContainer>
        <Link to={`/overview/${profileStore.selectedProfile}/export`}>
          <IconButton icon={IconType.Export} size={IconSize.Medium} />
        </Link>
      </ButtonContainer>
      <YearContainer>
        <BiCaretLeft onClick={() => onYearChange(year - 1)} />
        <HeaderSmall> {year}</HeaderSmall>
        <BiCaretRight onClick={() => onYearChange(year + 1)} />
      </YearContainer>
      <ButtonContainer right>
        <Link
          to={`/overview/${profileStore.selectedProfile}/new/transfer/${today}`}
        >
          <IconButton icon={IconType.Transfer} size={IconSize.Medium} />
        </Link>
        <Link
          to={`/overview/${profileStore.selectedProfile}/new/transaction/${today}`}
        >
          <IconButton icon={IconType.Plus} size={IconSize.Medium} />
        </Link>
        <MobileButtons>
          {onListClick && (
            <IconButton
              icon={IconType.Stats}
              size={IconSize.Medium}
              onClick={onListClick}
            />
          )}
          {onStatsClick && (
            <IconButton
              icon={IconType.Collection}
              size={IconSize.Medium}
              onClick={onStatsClick}
            />
          )}
        </MobileButtons>
      </ButtonContainer>
    </Container>
  );
};

export default DashboardHeader;

import api from "./api";
import { Account } from "./types/Account";

export function getAccounts(profileId: string) {
  return Promise.resolve({
    frequentlyUsed: [],
    projects: [
      {
        name: "Current",
        id: 1,
        color: "#FF0000",
      },
      {
        name: "Cash",
        id: 1,
        color: "#FF00DD",
      },
      {
        name: "Credit Card",
        id: 1,
        color: "#FF00FF",
      },
    ],
  });
}

export async function createAccount(
  profileId: string,
  name: string,
  color: string,
  initialBalance: number
): Promise<Account> {
  const response = await api.post(
    api.uri().segment("profile").segment(profileId).segment("account").href(),
    { name, color, initialBalance }
  );
  const { body } = response;
  return body.account;
}

import { Account } from "./Account";
import { Subject } from "./Subject";
import { Category } from "./Category";

export enum TransactionType {
  Transaction = "transaction",
  Transfer = "transfer",
}

export type Transaction = {
  id: number;
  profile_id: number;
  amount: number;
  fromAmount?: number;
  toAmount?: number;
  type: TransactionType;
  created_at: string;
  updated_at: string;
  date: string;
  note: string;
  subject?: Subject;
  category?: Category;
  fromAccount?: Account;
  toAccount?: Account;
  account?: Account;
  vat?: number;
  transaction_id?: number;
};

export type TransactionWithTotal = {
  transaction: Transaction;
  total: {
    account: { year: number; month: number };
    fromAccount: { year: number; month: number };
    toAccount: { year: number; month: number };
    category: { year: number; month: number };
    subject: { year: number; month: number };
  };
};

export type DateKeyedTransactionWithTotal = {
  [key: string]: TransactionWithTotal[];
};

export enum TransactionFilter {
  CategoryId = "categoryId",
  SubjectId = "subjectId",
  AccountId = "accountId",
  ProjectId = "projectId",
}

export type TransactionFilters = {
  [TransactionFilter.CategoryId]: number | null;
  [TransactionFilter.AccountId]: number | null;
  [TransactionFilter.SubjectId]: number | null;
  [TransactionFilter.ProjectId]: number | null;
};

import api from "./api";
import { Profile } from "./types";
import { Account } from "./types/Account";
import { BalancesObject } from "./types/Balances";
import { Category } from "./types/Category";
import { Project } from "./types/Project";
import { Subject } from "./types/Subject";
import { Transaction, TransactionFilters } from "./types/Transaction";

export class ProfileApi {
  getOverview(profileId: string, year: number) {
    return Promise.resolve({
      year: year,
      accounts: [
        {
          id: 1,
          name: "current",
          colour: "#FF0000",
          month: 12,
          ytd: 213,
          balance: 200,
        },
        {
          id: 2,
          name: "cash",
          colour: "#FFFF00",
          month: 12,
          ytd: 213,
          balance: 400,
        },
        {
          id: 3,
          name: "savings",
          colour: "#FF00FF",
          month: 12,
          ytd: 213,
          balance: 300,
        },
      ],
      income: 12313,
      expense: 324,
      balance: 23,
    });
  }

  getBalances(profileId: string, year: number): Promise<BalancesObject> {
    return api
      .get(
        api
          .uri()
          .segment("profile")
          .segment(profileId)
          .segment("balances")
          .segment(year.toString())
          .href()
      )
      .then((response) => {
        const { body } = response;
        return body.balances;
      });
  }

  getAccounts(profileId: string, year: number): Promise<Account[]> {
    return api
      .get(
        api
          .uri()
          .segment("profile")
          .segment(profileId)
          .segment("accounts")
          .segment(year.toString())
          .href()
      )
      .then((response) => {
        const { body } = response;
        return body.accounts;
      });
  }

  getDayTransactions(
    profileId: string,
    year: number,
    filters: TransactionFilters
  ): Promise<{ transactions: Transaction[] }> {
    const url = api
      .uri()
      .segment("profile")
      .segment(profileId)
      .segment("transactions")
      .segment(year.toString());
    if (filters.categoryId) {
      url.addQuery("c", filters.categoryId);
    }
    if (filters.accountId) {
      url.addQuery("a", filters.accountId);
    }
    if (filters.subjectId) {
      url.addQuery("s", filters.subjectId);
    }
    if (filters.projectId) {
      url.addQuery("p", filters.projectId);
    }
    return api.get(url.href()).then((response) => {
      const { body } = response;
      return body;
    });
  }

  getSubjects(
    profileId: string
  ): Promise<{ subjects: Subject[]; frequentlyUsed: Subject[] }> {
    return api
      .get(
        api
          .uri()
          .segment("profile")
          .segment(profileId)
          .segment("subjects")
          .href()
      )
      .then((response) => {
        const { body } = response;
        return body;
      });
  }

  getProjects(
    profileId: string
  ): Promise<{ projects: Project[]; frequentlyUsed: Subject[] }> {
    return api
      .get(
        api
          .uri()
          .segment("profile")
          .segment(profileId)
          .segment("projects")
          .href()
      )
      .then((response) => {
        const { body } = response;
        return body;
      });
  }

  getCategories(
    profileId: string
  ): Promise<{ categories: Category[]; frequentlyUsed: Category[] }> {
    return api
      .get(
        api
          .uri()
          .segment("profile")
          .segment(profileId)
          .segment("categories")
          .href()
      )
      .then((response) => {
        const { body } = response;
        return body;
      });
  }
}

const profileApi = new ProfileApi();
export default profileApi;

export async function createProfile(name: string): Promise<Profile> {
  const response = await api.post(api.uri().segment("profile").href(), {
    name: name,
  });

  const { body } = response;
  return body.profile;
}

import styled from "styled-components";

import { INPUT_BACKGROUND, INPUT_PLACEHODER } from "constants/colors";

const StyledInput = styled.input`
  padding: 0px 20px;
  background-color: ${INPUT_BACKGROUND};
  border-radius: 0.5rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 0;
  width: 100%;
  outline: 0;
  font-size: 1rem;
  &::placeholder {
    color: ${INPUT_PLACEHODER};
  }
`;

export const TextInput = ({ onChange, ...props }) => {
  const handleChange = (e) => {
    onChange && onChange(e.target.value);
  };

  return <StyledInput {...props} onChange={handleChange} />;
};

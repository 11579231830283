import { useDrag } from "@use-gesture/react";
import { Filters } from "components/interface/Filters";
import DayTransactions from "components/interface/day-transactions/TransactionList";
import InterfaceHeader from "components/interface/header";
import { Overview } from "components/interface/overview";
import Modal from "components/ui/modal";
import { MediaQueries } from "constants/responsive";
import { Alerts } from "containers/AlertsContainer";
import ExportModal from "containers/modals/export";
import TransactionModal from "containers/modals/transaction";
import TransferModal from "containers/modals/transfer";
import { useStore } from "hooks";
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100vh;
  flex-direction: column;
`;
const HeaderContainer = styled.div``;
const Content = styled.div`
  overflow-x: hidden;
  flex-grow: 1;
  display: flex;
`;
const ScrollContainer = styled.div<{ swipeIndex: number }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: all 0.3s;
  ${MediaQueries.Tablet} {
    width: 200vw;
    ${({ swipeIndex }) => `transform: translateX(-${swipeIndex * 50}%);`}
  }
`;
const DayTransactionsContainer = styled.div`
  width: 60%;
  flex-grow: 1;
  padding: 0 1rem 1rem 1rem;
  ${MediaQueries.Tablet} {
    margin-right: 0px;
    width: 100vw;
  }
  ${MediaQueries.Mobile} {
    padding: 0 0.5rem 0.5rem 0.5rem;
  }
`;
const OverviewContainer = styled.div`
  width: 40%;
  flex-grow: 1;
  padding: 0 1rem 1rem 1rem;
  overflow-y: scroll;
  ${MediaQueries.Tablet} {
    margin-left: 0px;
    width: 100vw;
  }
  ${MediaQueries.Mobile} {
    padding: 0 0.5rem 0.5rem 0.5rem;
  }
`;
const Separator = styled.div`
  /* width: 1rem; */
  ${MediaQueries.Tablet} {
    width: 0;
  }
`;
const LoaderContianer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type ProfileCheckProps = {
  children: ReactNode;
};

const ProfileCheck = ({ children }: ProfileCheckProps) => {
  const { profileId } = useParams();
  if (profileId) {
    return <>{children}</>;
  }
  return <Navigate to="/" />;
};

const ProfileDashboard = () => {
  const navigate = useNavigate();
  const { profileStore, layoutStore } = useStore();
  const { selectedYear, selectedProfile, setSelectedYear, transactionFilters } =
    profileStore;
  const {
    mainInterface: { swipeIndex, setSwipeIndex },
  } = layoutStore;
  const { profileId } = useParams();

  useEffect(() => {
    profileStore.setSelectedProfile(profileId!);
  }, [profileStore, profileId]);

  const bind = useDrag(({ swipe: [swipeX] }) => {
    if (swipeX < 0) {
      if (swipeIndex === 0) {
        setSwipeIndex(swipeIndex + 1);
      }
    }
    if (swipeX > 0) {
      if (swipeIndex > 0) {
        setSwipeIndex(swipeIndex - 1);
      }
    }
  });

  const { isSuccess: balancesSuccess } = useQuery({
    queryKey: ["balances", profileId, selectedYear, transactionFilters],
    queryFn: () => profileStore.getBalances(),
    staleTime: 1000,
    refetchOnWindowFocus: false,
  });

  const { isLoading: categoriesLoading } = useQuery({
    queryKey: ["categories", selectedProfile, selectedYear],
    queryFn: () => profileStore.getCategories(),
    staleTime: 1000,
    refetchOnWindowFocus: false,
  });

  const { isLoading: accountsLoading } = useQuery({
    queryKey: ["accounts", selectedProfile, selectedYear],
    queryFn: () => profileStore.getAccounts(),
    staleTime: 1000,
    refetchOnWindowFocus: false,
  });

  const { isLoading: subjectsLoading } = useQuery({
    queryKey: ["subjects", selectedProfile, selectedYear],
    queryFn: () => profileStore.getSubjects(),
    staleTime: 1000,
    refetchOnWindowFocus: false,
  });

  useQuery({
    queryKey: ["projects", selectedProfile, selectedYear],
    queryFn: () => profileStore.getProjects(),
    staleTime: 1000,
    refetchOnWindowFocus: false,
  });

  const { isLoading: transactionLoading } = useQuery({
    queryKey: ["transactions", profileId, selectedYear, transactionFilters],
    queryFn: () => profileStore.getTransactions(),
    staleTime: 1000,
    refetchOnWindowFocus: false,
    enabled: balancesSuccess,
  });

  return (
    <ProfileCheck>
      <Container {...bind()}>
        <HeaderContainer>
          <InterfaceHeader
            year={selectedYear}
            onYearChange={(year: number) => {
              setSelectedYear(year);
            }}
            onListClick={swipeIndex === 1 ? () => setSwipeIndex(0) : undefined}
            onStatsClick={swipeIndex === 0 ? () => setSwipeIndex(1) : undefined}
          />
          <Filters />
        </HeaderContainer>
        <Content>
          <ScrollContainer swipeIndex={swipeIndex}>
            <OverviewContainer>
              {categoriesLoading || accountsLoading || subjectsLoading ? (
                <LoaderContianer>{"Loading..."}</LoaderContianer>
              ) : (
                <Overview />
              )}
            </OverviewContainer>
            <Separator />
            <DayTransactionsContainer>
              {transactionLoading ? (
                <LoaderContianer>{"Loading..."}</LoaderContianer>
              ) : (
                <DayTransactions />
              )}
            </DayTransactionsContainer>
          </ScrollContainer>
        </Content>
      </Container>
      <Alerts />
      <Routes>
        <Route
          path={`new/transaction/:date`}
          element={
            <Modal height={700} width={400}>
              <TransactionModal
                onComplete={() => navigate(-1)}
                onCancel={() => navigate(-1)}
              />
            </Modal>
          }
        />
        <Route
          path={`edit/transaction/:id`}
          element={
            <Modal height={700} width={400}>
              <TransactionModal
                onComplete={() => navigate(-1)}
                onCancel={() => navigate(-1)}
              />
            </Modal>
          }
        />
        <Route
          path={`new/transfer/:date`}
          element={
            <Modal height={700} width={400}>
              <TransferModal
                onComplete={() => navigate(-1)}
                onCancel={() => navigate(-1)}
              />
            </Modal>
          }
        />
        <Route
          path={`edit/transfer/:id`}
          element={
            <Modal height={700} width={400}>
              <TransferModal
                onComplete={() => navigate(-1)}
                onCancel={() => navigate(-1)}
              />
            </Modal>
          }
        />
        <Route
          path={`export`}
          element={
            <Modal width={400}>
              <ExportModal />
            </Modal>
          }
        />
      </Routes>
    </ProfileCheck>
  );
};

export default observer(ProfileDashboard);

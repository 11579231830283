import { useCallback, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";

import {
  SELECT_LIST_ITEM_BACKGROUND,
  SELECT_LIST_ITEM_SEPARATOR,
} from "constants/colors";
import { MediaQueries } from "constants/responsive";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
`;
const IndicatorContainer = styled.div`
  width: 3rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
const Indicator = styled.div`
  flex-grow: 1;
  font-size: 0.6rem;
  background-color: ${SELECT_LIST_ITEM_BACKGROUND};
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px 0;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0.5rem;
`;
const OptionsContainer = styled.div`
  overflow-y: scroll;
  flex-grow: 1;
`;
const OptionContainer = styled.div`
  padding: 0px 1rem;
  background-color: ${SELECT_LIST_ITEM_BACKGROUND};
  border-radius: 0.5rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  cursor: pointer;
  ${MediaQueries.Mobile} {
    margin-bottom: 0.5rem;
  }
`;
const ColorIndicator = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background-color: ${(props) => props.color};
`;
const Separator = styled.div`
  margin: 0 3rem 1rem 3rem;
  height: 0.5rem;
  background-color: ${SELECT_LIST_ITEM_SEPARATOR};
  border-radius: 0.25rem;
`;

type ItemSelectorItem = {
  id: number;
  name: string;
  color?: string;
};

type ItemGroups = { [key: string]: ItemSelectorItem[] };

type ItemSelectorProps = {
  items: ItemSelectorItem[];
  frequentlyUsed?: any[];
  onChange: (data: any) => void;
  showIndex?: boolean;
};

export const ItemSelector = ({
  items,
  frequentlyUsed = [],
  onChange,
  showIndex = false,
}: ItemSelectorProps) => {
  const groupRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const handleOnClick = (data: any) => () => {
    onChange(data);
  };

  const handleScrollToGroup = useCallback(
    (groupKey: string) => {
      if (groupRefs.current && groupRefs.current[groupKey]) {
        groupRefs.current[groupKey]?.scrollIntoView();
      }
    },
    [groupRefs]
  );

  const registerRef = (ref: HTMLDivElement | null, groupKey: string) => {
    if (!(groupKey in groupRefs.current)) {
      groupRefs.current[groupKey] = ref;
    }
  };

  const groups = useMemo(() => {
    const groups: ItemGroups = {};
    items.forEach((item) => {
      let key = item.name[0].toLowerCase();
      if (!(key in groups)) {
        groups[key] = [];
      }
      groups[key].push(item);
    });
    return groups;
  }, [items]);

  useEffect(() => {
    const handleKeyPress = (e: Event) => {
      //@ts-ignore
      handleScrollToGroup(e.key!);
    };

    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [handleScrollToGroup]);

  return (
    <Container>
      {showIndex && (
        <IndicatorContainer>
          {Object.keys(groups).map((groupKey) => (
            <Indicator
              key={`${groupKey}-indicator`}
              onClick={() => handleScrollToGroup(groupKey)}
            >
              {groupKey}
            </Indicator>
          ))}
        </IndicatorContainer>
      )}
      <OptionsContainer>
        {frequentlyUsed.map((item) => (
          <OptionContainer key={`fu-${item.id}`} onClick={handleOnClick(item)}>
            {item.name}
          </OptionContainer>
        ))}

        {frequentlyUsed.length > 0 && <Separator />}

        {Object.keys(groups).map((groupKey) => {
          return (
            <div
              key={`${groupKey}-group`}
              ref={(ref) => registerRef(ref, groupKey)}
            >
              {groups[groupKey].map((item: ItemSelectorItem) => {
                return (
                  <OptionContainer
                    key={`item-${item.id}`}
                    onClick={handleOnClick(item)}
                  >
                    {item.name}
                    {item.color && <ColorIndicator color={item.color} />}
                  </OptionContainer>
                );
              })}
            </div>
          );
        })}
      </OptionsContainer>
    </Container>
  );
};

/** {items.map((item) => {
          return (
            <OptionContainer
              key={`item-${item.id}`}
              onClick={handleOnClick(item)}
              ref={item.name === "DB" ? ref : null}
            >
              {item.name}
              {item.color && (
                <ColorIndicator
                  color={hslToHex(item.color, 100, ACCOUNT_LIGHTNESS)}
                />
              )}
            </OptionContainer>
          );
        })} */

import api from "./api";
import { BalancesObject } from "./types/Balances";
import { Transaction } from "./types/Transaction";

export function getTransactions(
  profileId: string,
  year: number,
  fromDate: Date
) {
  return Promise.resolve({
    transactions: {
      "2021-05-10": [
        {
          id: "123213",
          type: "transaction",
          subject: {
            id: 1,
            name: "Netto",
          },
          category: {
            id: 2,
            name: "Groceries",
          },
          account: {
            id: 1,
            name: "Cash",
            color: "#00FF00",
          },
          project: {
            id: 2,
            name: "nothing",
          },
          var: 19,
          note: "Sausages",
          amount: -120,
        },
        {
          id: "123213asd",
          type: "transaction",
          subject: {
            id: 1,
            name: "Netto",
          },
          category: {
            id: 2,
            name: "Groceries",
          },
          account: {
            id: 1,
            name: "Cash",
            color: "#00FF00",
          },
          project: null,
          var: 19,
          note: "beer",
          amount: -120,
        },
        {
          id: "123213sdf",
          type: "transfer",
          fromAccount: {
            id: 1,
            name: "Current",
            color: "#0000DD",
          },
          toAccount: {
            id: 1,
            name: "Cash",
            color: "#00FF00",
          },
          note: "bla",
          amount: 200,
        },
      ],
      "2021-05-09": [
        {
          id: "123213sf",
          type: "transaction",
          subject: {
            id: 1,
            name: "Fart",
          },
          category: {
            id: 2,
            name: "Cosmetics",
          },
          account: {
            id: 1,
            name: "Credit Card",
            color: "#00FFDD",
          },
          project: null,
          var: 0,
          note: "Beer",
          amount: -120,
        },
        {
          id: "123213sdf",
          type: "transfer",
          fromAccount: {
            id: 1,
            name: "Current",
            color: "#0000DD",
          },
          toAccount: {
            id: 1,
            name: "Cash",
            color: "#00FF00",
          },
          note: "bla",
          amount: 200,
        },
        {
          id: "123213asd",
          type: "transaction",
          subject: {
            id: 1,
            name: "Netto",
          },
          category: {
            id: 2,
            name: "Groceries",
          },
          account: {
            id: 1,
            name: "Cash",
            color: "#00FF00",
          },
          project: null,
          var: 19,
          note: "beer",
          amount: -120,
        },
      ],
      "2021-05-08": [
        {
          id: "123213sf",
          type: "transaction",
          subject: {
            id: 1,
            name: "Fart",
          },
          category: {
            id: 2,
            name: "Cosmetics",
          },
          account: {
            id: 1,
            name: "Credit Card",
            color: "#00FFDD",
          },
          project: null,
          var: 0,
          note: "Beer",
          amount: -120,
        },
        {
          id: "123213sdf",
          type: "transfer",
          fromAccount: {
            id: 1,
            name: "Current",
            color: "#0000DD",
          },
          toAccount: {
            id: 1,
            name: "Cash",
            color: "#00FF00",
          },
          note: "bla",
          amount: 200,
        },
        {
          id: "123213asd",
          type: "transaction",
          subject: {
            id: 1,
            name: "Netto",
          },
          category: {
            id: 2,
            name: "Groceries",
          },
          account: {
            id: 1,
            name: "Cash",
            color: "#00FF00",
          },
          project: null,
          var: 19,
          note: "beer",
          amount: -120,
        },
      ],
    },
    nextDate: "2021-02-01",
  });
}

export async function createTransaction(
  profileId: string,
  accountId: number,
  amount: number,
  categoryId: number,
  date: string,
  subjectId: number,
  vat: number,
  note?: string,
  projectId?: number
): Promise<{ transaction: Transaction; balance: BalancesObject }> {
  const response = await api.post(
    api
      .uri()
      .segment("profile")
      .segment(profileId)
      .segment("transaction")
      .href(),
    {
      accountId,
      amount,
      categoryId,
      date,
      subjectId,
      vat,
      note,
      projectId,
    }
  );
  const { body } = response;
  const { transaction, balance } = body;
  return { transaction, balance };
}

export async function saveTransaction(
  id: number,
  profileId: string,
  accountId: number,
  amount: number,
  categoryId: number,
  date: string,
  subjectId: number,
  vat: number,
  note?: string,
  projectId?: number
): Promise<{ transaction: Transaction; balance: BalancesObject }> {
  const response = await api.patch(
    api
      .uri()
      .segment("profile")
      .segment(profileId)
      .segment("transaction")
      .segment(`${id}`)
      .href(),
    {
      accountId,
      amount,
      categoryId,
      date,
      subjectId,
      vat,
      note,
      projectId,
    }
  );
  const { body } = response;
  const { transaction, balance } = body;
  return { transaction, balance };
}

export async function createTransfer(
  profileId: string,
  fromAccountId: string,
  toAccountId: string,
  amount: number,
  date: string,
  note?: string
): Promise<{ transaction: Transaction; balance: BalancesObject }> {
  const response = await api.post(
    api.uri().segment("profile").segment(profileId).segment("transfer").href(),
    {
      fromAccountId,
      toAccountId,
      amount,
      date,
      note,
    }
  );
  const { body } = response;
  const { transaction, balance } = body;
  return { transaction, balance };
}

export async function saveTransfer(
  id: number,
  transferId: number,
  profileId: string,
  fromAccountId: string,
  toAccountId: string,
  amount: number,
  date: string,
  note?: string
): Promise<{ transaction: Transaction; balance: BalancesObject }> {
  const response = await api.patch(
    api
      .uri()
      .segment("profile")
      .segment(profileId)
      .segment("transfer")
      .segment(`${id}`)
      .href(),
    {
      transferId,
      fromAccountId,
      toAccountId,
      amount,
      date,
      note,
    }
  );
  const { body } = response;
  const { transaction, balance } = body;
  return { transaction, balance };
}

export async function deleteTransaction(profileId: string, id: number) {
  const response = await api.delete(
    api
      .uri()
      .segment("profile")
      .segment(profileId)
      .segment("transaction")
      .segment(`${id}`)
      .href()
  );

  return response;
}

export async function exportVat(
  profileId: string,
  year: number,
  month: number
): Promise<Transaction[]> {
  const response = await api.get(
    api
      .uri()
      .segment("profile")
      .segment(profileId)
      .segment("export")
      .segment(`${year}`)
      .segment("vat")
      .segment(`${month}`)
      .href()
  );

  const { body } = response;
  const { transactions } = body;
  return transactions;
}

import api from "./api";
import { Project } from "./types/Project";

export function getProjects(profileId: string) {
  return Promise.resolve({
    frequentlyUsed: [],
    projects: [
      {
        name: "South Africa",
        id: 1,
        defaultVat: 0,
        lastUsedCategory: { name: "Telecomms", id: 1 },
      },
      {
        name: "Bubblefest",
        id: 1,
        defaultVat: 0,
        lastUsedCategory: { name: "Groceries", id: 1 },
      },
      {
        name: "Minicasa",
        id: 1,
        defaultVat: 0,
        lastUsedCategory: null,
      },
    ],
  });
}

export async function createProject(
  profileId: string,
  name: string,
  budget: number
): Promise<Project> {
  const response = await api.post(
    api.uri().segment("profile").segment(profileId).segment("project").href(),
    { name, budget }
  );
  const { body } = response;
  return body.project;
}

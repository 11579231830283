import { makeAutoObservable } from "mobx";

import api, { Api } from "api/api";
import { AuthStore } from "state/AuthStore";
import { UserStore } from "state/UserStore";
import { ProfileStore } from "state/ProfileStore";
import { Profile, User } from "api/types";
import { LayoutStore } from "./LayoutStore";
import { AlertStore } from "./AlertStore";

export class RootStore {
  public isReady: boolean = false;
  public authStore: AuthStore;
  public userStore: UserStore;
  public profileStore: ProfileStore;
  public layoutStore: LayoutStore;
  public alertStore: AlertStore;
  public api: Api;
  private eventListeners: { [key: string]: Function[] } = {};

  constructor() {
    this.api = api;

    const authData = localStorage?.getItem("auth");
    try {
      const { token, user, profiles } = JSON.parse(authData || "");
      this.api.setAuthToken(token);
      this.authStore = new AuthStore(this, token);
      this.userStore = new UserStore(this, user, profiles);
      this.profileStore = new ProfileStore(this, profiles);
    } catch (error) {
      this.authStore = new AuthStore(this);
      this.userStore = new UserStore(this);
      this.profileStore = new ProfileStore(this);
    }
    this.layoutStore = new LayoutStore();
    this.alertStore = new AlertStore();
    this.isReady = true;
    makeAutoObservable(this);
  }

  persistSession(token: string, user: User, profiles: Profile[]) {
    localStorage?.setItem("auth", JSON.stringify({ token, user, profiles }));
  }

  cleanSession() {
    localStorage?.setItem("auth", JSON.stringify({}));
  }

  addEventListener(type: string, callback: Function): void {
    if (!this.eventListeners[type]) {
      this.eventListeners[type] = [];
    }
    this.eventListeners[type].push(callback);
  }

  emit(type: string, data: any): void {
    if (Array.isArray(this.eventListeners[type])) {
      this.eventListeners[type].forEach((callback) => {
        callback(data);
      });
    }
  }
}

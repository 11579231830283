import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import {
  ButtonType,
  FieldContainer,
  FlexButton,
  TextInput,
} from "components/ui/forms";

// import { login } from "api/auth";
import { ButtonText } from "components/ui/Typography";
import { useStore } from "hooks";
import styled from "styled-components";
import { z } from "zod";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
const ContentContainer = styled.div`
  padding: 1rem;
  border-radius: 1rem;
  background-color: white;
  width: 300px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05);
`;
const TitleContainer = styled.div`
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;
const LinksContainer = styled.div`
  margin-top: 1rem;
`;
const StyledLink = styled(Link)`
  cursor: pointer;
  color: black;
`;

const validation = z
  .object({
    name: z.string(),
    email: z.string().email(),
    password: z.string(),
    password_rpt: z.string(),
  })
  .superRefine(({ password, password_rpt }, ctx) => {
    if (password_rpt !== password) {
      console.log("first");
      ctx.addIssue({
        code: "custom",
        message: "The passwords must match",
      });
    }
  });

export default function Join() {
  const { authStore } = useStore();
  const [data, setData] = useState({});

  const setFieldValue = (field: string) => (value: any) => {
    setData({ ...data, [field]: value });
  };

  const disabled = useMemo(() => {
    try {
      validation.parse(data);
      return false;
    } catch (err) {
      return true;
    }
  }, [data]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const validatedData = validation.parse(data);
      authStore
        .register(
          validatedData.name,
          validatedData.email,
          validatedData.password
        )
        .then(() => {
          // onComplete();
        });
    } catch (err) {
      // setErrors(getKeyedValidationErrors(validationError as ValidationError));
    }
  };

  return (
    <Container>
      <TitleContainer>Join</TitleContainer>
      <ContentContainer>
        <form onSubmit={onSubmit}>
          <FieldContainer>
            <TextInput placeholder="name" onChange={setFieldValue("name")} />
          </FieldContainer>
          <FieldContainer>
            <TextInput
              placeholder="email address"
              onChange={setFieldValue("email")}
            />
          </FieldContainer>
          <FieldContainer>
            <TextInput
              placeholder="password"
              type="password"
              onChange={setFieldValue("password")}
            />
          </FieldContainer>
          <FieldContainer>
            <TextInput
              placeholder="password again"
              type="password"
              onChange={setFieldValue("password_rpt")}
            />
          </FieldContainer>
          <FlexButton type={ButtonType.Submit} disabled={disabled}>
            <ButtonText>Join</ButtonText>
          </FlexButton>
        </form>
      </ContentContainer>
      <LinksContainer>
        <StyledLink to="/login">Logon</StyledLink>
      </LinksContainer>
    </Container>
  );
}

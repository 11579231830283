import { createRef, useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { dateObjectToString } from "util/date";
import { useStore } from "hooks";

import PaneNavigator from "components/ui/pane-navigator";
import TransactionForm from "components/forms/transaction-form";
import DateSelector from "components/panes/date-selector";
import Calculator from "components/panes/Calculator";
import SelectSubject from "../select-subject";
import SelectCategory from "../select-category";
import SelectProject from "../select-project";
import SelectAccount from "../select-account";

const AddTransaction = ({ onComplete, onCancel }) => {
  const { date, id } = useParams();
  const { profileStore } = useStore();
  const { profileId } = profileStore;
  const navigator = createRef();
  const [data, setData] = useState({
    date: date ? new Date(date) : new Date(),
    amount: 0,
  });

  const {
    mutate,
    data: createResponse,
    isSuccess,
  } = useMutation({ mutationFn: profileStore.createTransaction });

  useEffect(() => {
    if (id) {
      const transaction = profileStore.getTransaction(parseInt(id));
      if (transaction) {
        return setData({ ...transaction, date: new Date(transaction.date) });
      }
      onCancel();
    }
  }, [id, onCancel, profileStore]);

  const goBack = () => {
    navigator.current.goBack();
  };

  const handleSubmit = (data) => {
    mutate({
      id: id ? parseInt(id) : null,
      account: data.account.id,
      amount: data.amount,
      category: data.category.id,
      date: dateObjectToString(data.date),
      subject: data.subject.id,
      vat: data.tax,
      note: data.note,
      project: data.project ? data.project.id : null,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      onComplete(createResponse);
    }
  }, [isSuccess, createResponse, onComplete]);

  const handleDateChange = (date) => {
    setData({ ...data, date: date });
    goBack();
  };

  const handleSubjectChange = (subject) => {
    if (!data.tax && subject.defaultVat > 0) {
      setData({ ...data, subject: subject, tax: subject.defaultVat });
    } else {
      setData({ ...data, subject: subject });
    }
    goBack();
  };

  const handleCategoryChange = (category) => {
    setData({ ...data, category: category });
    goBack();
  };

  const handleProjectChange = (project) => {
    setData({ ...data, project: project });
    goBack();
  };

  const handleAccountChange = (account) => {
    setData({ ...data, account: account });
    goBack();
  };

  const handleDirectionChange = (direction) => {
    let amount = data.amount || 0;
    amount = direction === "-" ? -amount : Math.abs(amount);
    setData({ ...data, amount: amount });
  };

  const handleNoteChange = (note) => {
    setData({ ...data, note: note });
  };

  const handleAmountChange = (amount) => {
    setData({ ...data, amount: data.amount < 0 ? -amount : amount });
    goBack();
  };

  const handleTaxChange = (tax) => {
    setData({ ...data, tax: tax });
    goBack();
  };

  const openCalendar = () => {
    navigator.current.navigateTo(
      <DateSelector onChange={handleDateChange} date={data.date} />
    );
  };

  const openSelectSubject = () => {
    navigator.current.navigateTo(
      <SelectSubject
        onChange={handleSubjectChange}
        onCancel={goBack}
        profileId={profileId}
        selected={data.subject}
      />
    );
  };

  const openSelectCategory = () => {
    navigator.current.navigateTo(
      <SelectCategory
        onChange={handleCategoryChange}
        onCancel={goBack}
        profileId={profileId}
        selected={data.category}
      />
    );
  };

  const openSelectProject = () => {
    navigator.current.navigateTo(
      <SelectProject
        onChange={handleProjectChange}
        onCancel={goBack}
        profileId={profileId}
        selected={data.project}
      />
    );
  };

  const openSelectAccount = () => {
    navigator.current.navigateTo(
      <SelectAccount
        onChange={handleAccountChange}
        onCancel={goBack}
        profileId={profileId}
        selected={data.account}
      />
    );
  };

  const openAmount = () => {
    navigator.current.navigateTo(
      <Calculator
        onAccept={handleAmountChange}
        onCancel={goBack}
        amount={Math.abs(data.amount)}
      />
    );
  };

  const openTax = () => {
    navigator.current.navigateTo(
      <Calculator
        onAccept={handleTaxChange}
        onCancel={goBack}
        amount={data.tax}
      />
    );
  };

  return (
    <PaneNavigator ref={navigator}>
      <TransactionForm
        onDateClick={openCalendar}
        data={data}
        onSubjectClick={openSelectSubject}
        onCatgoryClick={openSelectCategory}
        onProjectClick={openSelectProject}
        onAccountClick={openSelectAccount}
        onAmountClick={openAmount}
        onTaxClick={openTax}
        onDirectionChange={handleDirectionChange}
        onNoteChange={handleNoteChange}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      />
    </PaneNavigator>
  );
};

export default AddTransaction;

import { Alert } from "api/types/Alert";
import { makeAutoObservable } from "mobx";

export class AlertStore {
  public alerts: { [key: string]: Alert } = {};

  constructor() {
    makeAutoObservable(this);
  }

  trigger(alert: Alert) {
    const id = Date.now();
    this.alerts[id] = alert;
  }

  dismiss(id: string) {
    delete this.alerts[id];
  }
}

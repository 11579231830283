import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import { formatNumber } from "util/numbers";
import {
  CALCULATOR_CANCEL_BACKGROUND,
  CALCULATOR_NUMBER_BACKGROUND,
  INPUT_FOREGROUND,
} from "constants/colors";
import { ModalActionsWrapper } from "components/ui/layout/modal-actions-wrapper";

const AmountDisplay = styled.div`
  padding: 1rem;
  font-size: 2rem;
  text-align: right;
  color: ${INPUT_FOREGROUND};
`;
const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  margin: 0 -0.5rem 2rem;
`;
const ButtonContainer = styled.div<{ op: number | string }>`
  padding: 0.5rem;
  ${(props) =>
    props.op === 0 ? `width: ${(100 / 3) * 2}%` : `width: ${100 / 3}%`};
`;
const Button = styled.div<{ op: number | string }>`
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 100px;
  user-select: none;
  ${(props) => {
    switch (typeof props.op) {
      case "string":
        return `background-color: ${CALCULATOR_CANCEL_BACKGROUND}; color: white;`;
      case "number":
        return `background-color: ${CALCULATOR_NUMBER_BACKGROUND}; color: ${INPUT_FOREGROUND};`;
      default:
        return undefined;
    }
  }}
`;

interface Props {
  amount: number;
  onAccept: (amount: number) => void;
  onCancel: () => void;
}

export default function Calculator({ amount, onAccept, onCancel }: Props) {
  const [value, setValue] = useState<number>(amount || 0);

  const handleButton = useCallback(
    (op: any) => () => {
      switch (typeof op) {
        case "number":
          setValue((value) => {
            return value * 10 + op / 100;
          });
          return;
        default:
          if (op === "c") {
            return setValue(0);
          }
          return;
      }
    },
    [setValue]
  );

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      const key = parseInt(e.key);
      handleButton(isNaN(key) ? e.key : key)();
    };

    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [handleButton]);

  return (
    <ModalActionsWrapper
      onCancel={onCancel}
      onAccept={() => {
        onAccept(value);
      }}
    >
      <AmountDisplay>{formatNumber(value)}</AmountDisplay>
      <Buttons>
        {[7, 8, 9, 4, 5, 6, 1, 2, 3, 0, "c"].map((op) => (
          <ButtonContainer key={`key-${op}`} onClick={handleButton(op)} op={op}>
            <Button op={op}>{op}</Button>
          </ButtonContainer>
        ))}
      </Buttons>
    </ModalActionsWrapper>
  );
}

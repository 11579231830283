import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Link } from "react-router-dom";
import { GroupedVirtuoso } from "react-virtuoso";
import styled from "styled-components";

import { BalancesObject } from "api/types/Balances";
import {
  Transaction,
  TransactionFilter,
  TransactionType,
  TransactionWithTotal,
} from "api/types/Transaction";
import { ButtonType } from "components/ui/forms";
import { IconButton, IconType } from "components/ui/icon-button/IconButton";
import { MediaQueries } from "constants/responsive";
import { useStore } from "hooks";
import { hslToHex } from "util/color";
import { dateToPrettyString } from "util/date";
import { TransactionItem } from "./transaction/TransactionItem";

const Container = styled.div`
  height: 100%;
  font-size: 1rem;
  border-radius: 1rem 1rem 0 0;
  overflow: hidden;
  ${MediaQueries.Mobile} {
    font-size: 0.8rem;
  }
`;
const GroupHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: calc(30px + 2rem);
  background-color: ${hslToHex(180, 10, 90)}AA;
  backdrop-filter: blur(10px);
  padding: 1rem 0.5rem;
  border-radius: 1rem;
  overflow: hidden;
`;
const Date = styled.div`
  user-select: none;
`;
const Options = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  & a {
    margin: 0 0.5rem;
    ${MediaQueries.Mobile} {
      margin: 0 0.2rem;
    }
  }
`;
const TransactionContainer = styled.div`
  background-color: white;
  border-radius: 1rem;
  margin-bottom: 2px;
  border: 0.5rem solid white;
  overflow: hidden;
`;

function getTotals(transaction: Transaction, balances: BalancesObject) {
  const returnObj = {
    account: { month: 0, year: 0 },
    toAccount: { month: 0, year: 0 },
    fromAccount: { month: 0, year: 0 },
    category: { month: 0, year: 0 },
    subject: { month: 0, year: 0 },
  };

  if (transaction.type === TransactionType.Transaction) {
    if (transaction.account) {
      returnObj.account = {
        month:
          balances.accounts[transaction.account.id].month.in +
          balances.accounts[transaction.account.id].month.out,
        year:
          balances.accounts[transaction.account.id].ytd.in +
          balances.accounts[transaction.account.id].ytd.out,
      };
      balances.accounts[transaction.account.id].month.in -= transaction.amount;
      balances.accounts[transaction.account.id].ytd.in -= transaction.amount;
    }
    if (transaction.category) {
      returnObj.category = {
        month:
          balances.categories[transaction.category.id].month.in +
          balances.categories[transaction.category.id].month.out,
        year:
          balances.categories[transaction.category.id].ytd.in +
          balances.categories[transaction.category.id].ytd.out,
      };
      balances.categories[transaction.category.id].month.in -=
        transaction.amount;
      balances.categories[transaction.category.id].ytd.in -= transaction.amount;
    }
    if (transaction.subject) {
      returnObj.subject = {
        month:
          balances.subjects[transaction.subject.id].month.in +
          balances.subjects[transaction.subject.id].month.out,
        year:
          balances.subjects[transaction.subject.id].ytd.in +
          balances.subjects[transaction.subject.id].ytd.out,
      };
      balances.subjects[transaction.subject.id].month.in -= transaction.amount;
      balances.subjects[transaction.subject.id].ytd.in -= transaction.amount;
    }
  }
  if (transaction.type === TransactionType.Transfer) {
    if (transaction.fromAccount) {
      returnObj.fromAccount = {
        month:
          balances.accounts[transaction.fromAccount.id].month.in +
          balances.accounts[transaction.fromAccount.id].month.out,
        year:
          balances.accounts[transaction.fromAccount.id].ytd.in +
          balances.accounts[transaction.fromAccount.id].ytd.out,
      };
      balances.accounts[transaction.fromAccount.id].month.in -=
        transaction.fromAmount as number;
      balances.accounts[transaction.fromAccount.id].ytd.in -=
        transaction.fromAmount as number;
    }
    if (transaction.toAccount) {
      returnObj.toAccount = {
        month:
          balances.accounts[transaction.toAccount.id].month.in +
          balances.accounts[transaction.toAccount.id].month.out,
        year:
          balances.accounts[transaction.toAccount.id].ytd.in +
          balances.accounts[transaction.toAccount.id].ytd.out,
      };
      balances.accounts[transaction.toAccount.id].month.in -=
        transaction.toAmount as number;
      balances.accounts[transaction.toAccount.id].ytd.in -=
        transaction.toAmount as number;
    }
  }

  return returnObj;
}

const TransactionList = observer(() => {
  const { profileStore, alertStore } = useStore();
  const { transactions, transactionsList, balances } = profileStore;

  const transactionsWithTotals = useMemo(() => {
    const totals = { ...toJS(balances) };

    const totalledTransactions: TransactionWithTotal[] = transactionsList.map(
      (transaction) => {
        return { transaction, total: getTotals(transaction, totals) };
      }
    );

    return totalledTransactions;
  }, [balances, transactionsList]);

  const groupKeys = useMemo(() => {
    return Object.keys(transactions);
  }, [transactions]);

  const groupCounts = useMemo(() => {
    return Object.keys(transactions).map((date) => transactions[date].length);
  }, [transactions]);

  const handleCategoryClick = useCallback(
    (id: number | null) => {
      profileStore.setTransactionFilter(TransactionFilter.CategoryId, id);
    },
    [profileStore]
  );

  const handleAccountClick = useCallback(
    (id: number | null) => {
      profileStore.setTransactionFilter(TransactionFilter.AccountId, id);
    },
    [profileStore]
  );

  const handleSubjectClick = useCallback(
    (id: number | null) => {
      profileStore.setTransactionFilter(TransactionFilter.SubjectId, id);
    },
    [profileStore]
  );

  const handleDelete = useCallback(
    (transaction: Transaction) => {
      alertStore.trigger({
        title: "Sure?",
        buttons: [
          { type: ButtonType.Cancel, onClick: () => {} },
          {
            type: ButtonType.Accept,
            onClick: () => profileStore.deleteTransaction(transaction),
          },
        ],
      });
    },
    [profileStore, alertStore]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Container>
        <GroupedVirtuoso
          style={{
            height: "100%",
          }}
          groupCounts={groupCounts}
          groupContent={(index) => {
            const date = groupKeys[index];
            return (
              <GroupHeader>
                <Date>{dateToPrettyString(date)}</Date>
                <Options>
                  <Link to={`new/transfer/${date}`}>
                    <IconButton icon={IconType.Transfer} />
                  </Link>
                  <Link to={`new/transaction/${date}`}>
                    <IconButton icon={IconType.Plus} />
                  </Link>
                </Options>
              </GroupHeader>
            );
          }}
          itemContent={(index) => {
            return (
              <TransactionContainer>
                <TransactionItem
                  key={transactionsWithTotals[index].transaction.id}
                  transaction={transactionsWithTotals[index]}
                  onCategoryClick={handleCategoryClick}
                  onSubjectClick={handleSubjectClick}
                  onAccountClick={handleAccountClick}
                  onDelete={handleDelete}
                />
              </TransactionContainer>
            );
          }}
        />
      </Container>
    </DndProvider>
  );
});

export default TransactionList;

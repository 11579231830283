import { useCallback, useState } from "react";
import styled from "styled-components";

import { TransactionFilter } from "api/types/Transaction";
import { useStore } from "hooks";
import { OverviewTable } from "./OverviewTable";

const Container = styled.div`
  width: 100%;
  user-select: none;
`;
const SelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;
const SelectorOption = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: calc(100% / 4);
  cursor: pointer;
  ${({ selected }) =>
    selected
      ? "background-color: black; color: white;"
      : "background-color: white; color: black;"}
  &:first-of-type {
    border-radius: 0.5rem 0 0 0.5rem;
  }
  &:last-of-type {
    border-radius: 0 0.5rem 0.5rem 0;
  }
`;

export const Overview = () => {
  const { profileStore, layoutStore } = useStore();
  const { accounts, categories, subjects, projects, balances, selectedYear } =
    profileStore;
  const {
    mainInterface: { setSwipeIndex },
  } = layoutStore;
  const [selectedOverview, setSelectedOverview] = useState<string>("accounts");

  const handleCategoryClick = useCallback(
    (id: number | null) => {
      profileStore.setTransactionFilter(TransactionFilter.CategoryId, id);
      setSwipeIndex(1);
    },
    [setSwipeIndex, profileStore]
  );

  const handleAccountClick = useCallback(
    (id: number | null) => {
      profileStore.setTransactionFilter(TransactionFilter.AccountId, id);
      setSwipeIndex(1);
    },
    [setSwipeIndex, profileStore]
  );

  const handleSubjectClick = useCallback(
    (id: number | null) => {
      profileStore.setTransactionFilter(TransactionFilter.SubjectId, id);
      setSwipeIndex(1);
    },
    [setSwipeIndex, profileStore]
  );

  const handleProjectsClick = useCallback(
    (id: number | null) => {
      profileStore.setTransactionFilter(TransactionFilter.ProjectId, id);
      setSwipeIndex(1);
    },
    [setSwipeIndex, profileStore]
  );

  return (
    <Container>
      <SelectorContainer>
        <SelectorOption
          onClick={() => {
            setSelectedOverview("accounts");
          }}
          selected={selectedOverview === "accounts"}
        >
          Accounts
        </SelectorOption>
        <SelectorOption
          onClick={() => {
            setSelectedOverview("categories");
          }}
          selected={selectedOverview === "categories"}
        >
          Categories
        </SelectorOption>
        <SelectorOption
          onClick={() => {
            setSelectedOverview("subjects");
          }}
          selected={selectedOverview === "subjects"}
        >
          To/From
        </SelectorOption>
        <SelectorOption
          onClick={() => {
            setSelectedOverview("projects");
          }}
          selected={selectedOverview === "projects"}
        >
          Projects
        </SelectorOption>
      </SelectorContainer>
      {selectedOverview === "accounts" && (
        <OverviewTable
          items={accounts}
          balances={balances.accounts}
          onClick={handleAccountClick}
          year={selectedYear}
        />
      )}
      {selectedOverview === "categories" && (
        <OverviewTable
          items={categories.all}
          balances={balances.categories}
          onClick={handleCategoryClick}
          year={selectedYear}
        />
      )}
      {selectedOverview === "subjects" && (
        <OverviewTable
          items={subjects.all}
          balances={balances.subjects}
          onClick={handleSubjectClick}
          year={selectedYear}
        />
      )}
      {selectedOverview === "projects" && (
        <OverviewTable
          items={projects.all}
          balances={balances.projects}
          onClick={handleProjectsClick}
          year={selectedYear}
        />
      )}
    </Container>
  );
};

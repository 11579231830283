import ExcelJs from "exceljs";
import dayjs from "dayjs";
import { Transaction } from "api/types/Transaction";

export async function generateVatReport(transactions: Transaction[]) {
  const workbook = new ExcelJs.Workbook();
  const worksheet = workbook.addWorksheet("Vat");

  worksheet.columns = [
    { header: "Date", key: "date" },
    { header: "Who", key: "who" },
    { header: "Category", key: "category" },
    { header: "Incl.", key: "inc" },
    { header: "Excl.", key: "exc" },
    { header: "VAT Amount", key: "vatAmount" },
    { header: "Vat %", key: "vat" },
  ];

  const totals: {
    in: { [key: string]: { inc: number; exc: number; amount: number } };
    out: { [key: string]: { inc: number; exc: number; amount: number } };
    owed: number;
  } = {
    in: { total: { inc: 0, exc: 0, amount: 0 } },
    out: { total: { inc: 0, exc: 0, amount: 0 } },
    owed: 0,
  };

  transactions.forEach((transaction) => {
    const vat = (transaction.vat || 0) * 1;
    const vatAmount = (transaction.amount / (100 + vat)) * vat;
    const exc = transaction.amount - vatAmount;
    const inc = transaction.amount * 1;

    worksheet.addRow({
      date: dayjs(transaction.date).format("DD/MM/YYYY"),
      who: transaction.subject?.name,
      category: transaction.category?.name,
      inc: inc.toFixed(2),
      exc: exc.toFixed(2),
      vatAmount: vatAmount.toFixed(2),
      vat: transaction.vat || 0,
    });

    const vatString = `${vat}`;

    if (!(vatString in totals.in)) {
      totals.in[vatString] = { inc: 0, exc: 0, amount: 0 };
    }
    if (!(vatString in totals.out)) {
      totals.out[vatString] = { inc: 0, exc: 0, amount: 0 };
    }

    if (transaction.amount < 0) {
      totals.out[vatString].inc += inc;
      totals.out[vatString].exc += exc;
      totals.out[vatString].amount += vatAmount;
      totals.out.total.inc += inc;
      totals.out.total.exc += exc;
      totals.out.total.amount += vatAmount;
    } else {
      totals.in[vatString].inc += inc;
      totals.in[vatString].exc += exc;
      totals.in[vatString].amount += vatAmount;
      totals.in.total.inc += inc;
      totals.in.total.exc += exc;
      totals.in.total.amount += vatAmount;
    }
  });

  worksheet.addRow({});

  Object.keys(totals.in).forEach((v) => {
    worksheet.addRow({
      category: `In ${v}`,
      inc: totals.in[v].inc.toFixed(2),
      exc: totals.in[v].exc.toFixed(2),
      vatAmount: totals.in[v].amount.toFixed(2),
    });
  });

  worksheet.addRow({});

  Object.keys(totals.out).forEach((v) => {
    worksheet.addRow({
      category: `Out ${v}`,
      inc: totals.out[v].inc.toFixed(2),
      exc: totals.out[v].exc.toFixed(2),
      vatAmount: totals.out[v].amount.toFixed(2),
    });
  });

  worksheet.addRow({});

  worksheet.addRow({
    category: `Total Owed`,
    vatAmount: (totals.in.total.amount + totals.out.total.amount).toFixed(2),
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer]);
  return window.URL.createObjectURL(blob);
}

export async function generateTransactionReport(
  year: number,
  transactions: Transaction[]
) {
  const workbook = new ExcelJs.Workbook();
  const transactionSheet = workbook.addWorksheet("`Transactions`");
  const transferSheet = workbook.addWorksheet("Transfers");

  transactionSheet.columns = [
    { header: "Category", key: "category" },
    { header: "Who", key: "who" },
    { header: `${year}-01`, key: `01` },
    { header: `${year}-02`, key: `02` },
    { header: `${year}-03`, key: `03` },
    { header: `${year}-04`, key: `04` },
    { header: `${year}-05`, key: `05` },
    { header: `${year}-06`, key: `06` },
    { header: `${year}-07`, key: `07` },
    { header: `${year}-08`, key: `08` },
    { header: `${year}-09`, key: `09` },
    { header: `${year}-10`, key: `10` },
    { header: `${year}-11`, key: `11` },
    { header: `${year}-12`, key: `12` },
    { header: "Total", key: "total" },
    { header: "Total Excl.", key: "totalExcl" },
    { header: "Tax", key: "taxAmount" },
  ];

  transferSheet.columns = [
    { header: "Account", key: "account" },
    { header: `${year}-01`, key: `01` },
    { header: `${year}-02`, key: `02` },
    { header: `${year}-03`, key: `03` },
    { header: `${year}-04`, key: `04` },
    { header: `${year}-05`, key: `05` },
    { header: `${year}-06`, key: `06` },
    { header: `${year}-07`, key: `07` },
    { header: `${year}-08`, key: `08` },
    { header: `${year}-09`, key: `09` },
    { header: `${year}-10`, key: `10` },
    { header: `${year}-11`, key: `11` },
    { header: `${year}-12`, key: `12` },
    { header: "Total", key: "total" },
  ];

  const allTransactions: {
    [category: string]: {
      [who: string]: {
        months: number[];
        total: number;
        totalExcl: number;
        taxAmount: number;
      };
    };
  } = {};

  transactions.reduce((all, transaction) => {
    if (transaction.category && transaction.subject) {
      const category = transaction.category.name;
      const who = `${transaction.subject.name} ${transaction.vat}%`;
      if (!all.hasOwnProperty(category)) {
        all[category] = {};
      }

      if (!all[category].hasOwnProperty(who)) {
        all[category][who] = {
          total: 0,
          totalExcl: 0,
          taxAmount: 0,
          months: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        };
      }

      const date = new Date(transaction.date);

      all[category][who].months[date.getMonth() as number] +=
        transaction.amount;

      all[category][who].total += transaction.amount;
      if (transaction.vat) {
        all[category][who].totalExcl +=
          (transaction.amount / 100) * (100 - transaction.vat);
        all[category][who].taxAmount +=
          (transaction.amount / 100) * transaction.vat;
      } else {
        all[category][who].totalExcl += transaction.amount;
      }
    }
    return all;
  }, allTransactions);

  const allTransfers: {
    [account: string]: {
      months: number[];
      total: number;
    };
  } = {};

  transactions.reduce((all, transaction) => {
    if (transaction.fromAccount && transaction.toAccount) {
      if (!all.hasOwnProperty(transaction.fromAccount.name)) {
        all[transaction.fromAccount.name] = {
          total: 0,
          months: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        };
      }
      if (!all.hasOwnProperty(transaction.toAccount.name)) {
        all[transaction.toAccount.name] = {
          total: 0,
          months: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        };
      }

      const date = new Date(transaction.date);

      all[transaction.fromAccount.name].months[date.getMonth() as number] +=
        transaction.fromAmount || 0;
      all[transaction.toAccount.name].months[date.getMonth() as number] +=
        transaction.toAmount || 0;

      all[transaction.fromAccount.name].total += transaction.fromAmount || 0;
      all[transaction.toAccount.name].total += transaction.toAmount || 0;
    }
    return all;
  }, allTransfers);

  Object.keys(allTransactions).forEach((category) => {
    transactionSheet.addRow({ category: category });

    const subjects = allTransactions[category];
    Object.keys(subjects).forEach((subject) => {
      const transaction = subjects[subject];
      const row = {
        who: subject,
        total: transaction.total,
        totalExcl: transaction.totalExcl,
        taxAmount: transaction.taxAmount,
      };
      transaction.months.reduce((row, month, index) => {
        //@ts-ignore
        row[(index + 1).toString().padStart(2, "0")] = month;
        return row;
      }, row);

      transactionSheet.addRow(row);
    });

    transactionSheet.addRow({});
  });

  Object.keys(allTransfers).forEach((key) => {
    const transfer = allTransfers[key];
    const row = {
      account: key,
      total: transfer.total,
    };
    transfer.months.reduce((row, month, index) => {
      //@ts-ignore
      row[(index + 1).toString().padStart(2, "0")] = month;
      return row;
    }, row);

    transferSheet.addRow(row);
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer]);
  return window.URL.createObjectURL(blob);
}

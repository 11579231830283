import { MediaQueries } from "constants/responsive";
import { ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

const ModalBackground = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;
const ModalContainer = styled.div`
  padding: 20px 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0px;
    padding: 0;
  }
`;
const ModalContent = styled.div<{ width?: number; height?: number }>`
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  max-height: 100%;
  padding: 1rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05);

  ${MediaQueries.Mobile} {
    border-radius: 0px;
    max-height: unset;
    height: 100%;
    width: 100%;
  }
`;

type ModalProps = {
  children: ReactNode;
  width?: number;
  height?: number;
};

const Modal = ({ children, width, height }: ModalProps) => {
  useEffect(() => {
    const root = document.querySelector("#root");
    const body = document.querySelector("body");
    if (root && body) {
      root.className = "modal-open";
      body.style.overflow = "hidden";
    }
    return () => {
      if (root && body) {
        root.className = "";
        body.style.overflow = "unset";
      }
    };
  }, []);

  const handleBackgroundClick = (e: any) => {
    if (e.target?.id === "modal-background") {
      // history.goBack()
    }
  };

  return createPortal(
    <ModalBackground id="modal-background" onClick={handleBackgroundClick}>
      <ModalContainer>
        <ModalContent width={width} height={height}>
          {children}
        </ModalContent>
      </ModalContainer>
    </ModalBackground>,
    document.getElementById("modal-root")!
  );
};

export default Modal;

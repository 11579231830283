import React, { useState, useRef, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "hooks";

import SubjectForm from "components/forms/subject-form";
import PaneNavigator from "components/ui/pane-navigator";
import Calculator from "components/panes/Calculator";

export default function CreateSubject({ onComplete, onCancel }) {
  const { profileStore } = useStore();
  const navigator = useRef();
  const [data, setData] = useState({});

  const {
    data: createResponse,
    isSuccess,
    mutate,
  } = useMutation({ mutationFn: profileStore.createSubject });

  useEffect(() => {
    if (isSuccess) {
      onComplete(createResponse);
    }
  }, [isSuccess, createResponse, onComplete]);

  const goBack = () => {
    navigator.current.goBack();
  };

  const handleSubmit = (data) => {
    mutate(data);
  };

  const handleNameChange = (value) => {
    setData({ ...data, name: value });
  };

  const handleDefaultVatChange = (tax) => {
    setData({ ...data, defaultVat: tax });
    goBack();
  };

  const openDefaultVat = () => {
    navigator.current.navigateTo(
      <Calculator
        onAccept={handleDefaultVatChange}
        onCancel={goBack}
        amount={data.tax}
      />
    );
  };

  return (
    <PaneNavigator ref={(ref) => (navigator.current = ref)}>
      <SubjectForm
        onNameChange={handleNameChange}
        data={data}
        onDefaultVatClick={openDefaultVat}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </PaneNavigator>
  );
}

import { hslToHex } from "util/color";

export const ACCOUNT_LIGHTNESS = 70;

export const INPUT_BACKGROUND = "rgb(240,240,240)";
export const INPUT_FOREGROUND = "rgb(20,30,40)";
export const INPUT_PLACEHODER = "rgb(140,140,140)";
export const DIRECTION_IN_BACKGROUND = "#bdf261";
export const DIRECTION_OUT_BACKGROUND = "#f28061";
export const DIRECTION_DEFAULT_BACKGROUND = "rgb(240,240,240)";

export const ACCEPT_BUTTON_BACKGROUND = "#00eac3";
export const CANCEL_BUTTON_BACKGROUND = "#6b6b6b";
export const SUBMIT_BUTTON_BACKGROUND = hslToHex(100, 50, 70);
export const SUBMIT_BUTTON_BACKGROUND_DISABLED = hslToHex(100, 50, 80);

export const SELECT_LIST_ITEM_BACKGROUND = "rgb(240,240,240)";
export const SELECT_LIST_ITEM_SEPARATOR = "rgba(240,240,240,0.5)";

export const CALCULATOR_NUMBER_BACKGROUND = "rgb(240,240,240)";
export const CALCULATOR_CANCEL_BACKGROUND = "#eabb00";
export const CALCULATOR_FUNCTION_BACKGROUND = "#eabb00";

import { useRef } from "react";

import { useStore } from "hooks";

import { ItemSelector } from "components/panes/ItemSelector";
import { ModalActionsWrapper } from "components/ui/layout";
import PaneNavigator from "components/ui/pane-navigator";
import CreateCategory from "./create-category";

export default function SelectCategory({ onChange, onCancel }) {
  const { profileStore } = useStore();
  const { categories } = profileStore;
  const navigator = useRef();

  const goBack = () => {
    navigator.current.goBack();
  };

  const handleCreateCategory = (createData) => {
    onChange(createData);
  };

  const openCreateCategory = () => {
    navigator.current.navigateTo(
      <CreateCategory
        profileId={profileStore.selectedProfile}
        onCancel={goBack}
        onComplete={handleCreateCategory}
      />
    );
  };

  return (
    <PaneNavigator ref={navigator}>
      <ModalActionsWrapper onCreate={openCreateCategory} onCancel={onCancel}>
        <ItemSelector
          onChange={onChange}
          items={categories.all || []}
          frequentlyUsed={categories.frequentlyUsed || []}
          showIndex
        />
      </ModalActionsWrapper>
    </PaneNavigator>
  );
}

import {
  ReactNode,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

const NavigatorContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
const NavigatorScrollContainer = styled.div<{
  totalWidth: string;
  transform: number;
}>`
  display: flex;
  flex-direction: row;
  height: 100%;
  ${({ totalWidth }) => `width: ${totalWidth};`}
  ${({ transform }) => `transform: translateX(${transform}px);`}
  transition: transform 0.1s;
`;
const PaneContainer = styled.div<{ width: number }>`
  display: flex;
  width: ${({ width }) => `${width}%`};
`;

type PaneNavigatorProps = {
  children: ReactNode;
};

export type PaneNavigatorHandle = {
  navigateTo: (pane: ReactNode) => void;
  goBack: () => void;
};

export const PaneNavigator = forwardRef<
  PaneNavigatorHandle,
  PaneNavigatorProps
>(({ children }, ref) => {
  const [activePanes, setActivePanes] = useState<ReactNode[]>([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigationContainerRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    navigateTo: (pane: ReactNode) => {
      setActivePanes([...activePanes, pane]);
      setScrollPosition((index) => index + 1);
    },
    goBack: () => {
      setActivePanes([...activePanes.slice(0, activePanes.length - 1)]);
      setScrollPosition((index) => index - 1);
    },
  }));

  const getPaneCount = useCallback(() => {
    return children ? activePanes.length + 1 : activePanes.length;
  }, [children, activePanes]);

  const cssScrollPosition = useMemo(() => {
    if (navigationContainerRef.current) {
      return -scrollPosition * navigationContainerRef.current.offsetWidth;
    }
    return 0;
  }, [navigationContainerRef, scrollPosition]);

  const cssWidth = useMemo(() => {
    return `${getPaneCount() * 100}%`;
  }, [getPaneCount]);

  return (
    <NavigatorContainer ref={navigationContainerRef}>
      <NavigatorScrollContainer
        totalWidth={cssWidth}
        transform={cssScrollPosition}
      >
        {children && (
          <PaneContainer key="main-content" width={100 / getPaneCount()}>
            {children}
          </PaneContainer>
        )}
        {activePanes.map((pane, index) => (
          <PaneContainer key={`pane-${index}`} width={100 / getPaneCount()}>
            {pane}
          </PaneContainer>
        ))}
      </NavigatorScrollContainer>
    </NavigatorContainer>
  );
});

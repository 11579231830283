import { Subject } from "./types";
import api from "./api";

export function getSubjects(profileId: string) {
  return Promise.resolve({
    frequentlyUsed: [],
    projects: [
      {
        name: "Current",
        id: 1,
        color: "#FF0000",
      },
      {
        name: "Cash",
        id: 1,
        color: "#FF00DD",
      },
      {
        name: "Credit Card",
        id: 1,
        color: "#FF00FF",
      },
    ],
  });
}

export async function createSubject(
  profileId: string,
  name: string,
  defaultVat: number
): Promise<Subject> {
  const response = await api.post(
    api.uri().segment("profile").segment(profileId).segment("subject").href(),
    { name, defaultVat }
  );
  const { body } = response;
  return body.subject;
}

import { useState, useRef, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "hooks";

import ProjectForm from "components/forms/project-form";
import PaneNavigator from "components/ui/pane-navigator";
import Calculator from "components/panes/Calculator";

export default function CreateProject({ onComplete, onCancel }) {
  const { profileStore } = useStore();
  const navigator = useRef();
  const [data, setData] = useState({});

  const {
    data: createResonse,
    isSuccess,
    mutate,
  } = useMutation({ mutationFn: profileStore.createProject });

  useEffect(() => {
    if (isSuccess) {
      onComplete(createResonse);
    }
  }, [isSuccess, createResonse, onComplete]);

  const goBack = () => {
    navigator.current.goBack();
  };

  const handleSubmit = (data) => {
    mutate(data);
  };

  const handleNameChange = (value) => {
    setData({ ...data, name: value });
  };

  const handleBudgetChange = (value) => {
    setData({ ...data, budget: value });
    goBack();
  };

  const openBudget = () => {
    navigator.current.navigateTo(
      <Calculator
        onAccept={handleBudgetChange}
        onCancel={goBack}
        amount={data.budget}
      />
    );
  };

  return (
    <PaneNavigator ref={(ref) => (navigator.current = ref)}>
      <ProjectForm
        onNameChange={handleNameChange}
        data={data}
        onBudgetClick={openBudget}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </PaneNavigator>
  );
}

import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { ModalActionsWrapper } from "components/ui/layout";
import { SELECT_LIST_ITEM_BACKGROUND } from "constants/colors";
import { useStore } from "hooks";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  height: 100%;
  overflow-y: scroll;
`;
const OptionContainer = styled.div`
  padding: 0px 1rem;
  background-color: ${SELECT_LIST_ITEM_BACKGROUND};
  border-radius: 5px;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const formatter = new Intl.DateTimeFormat("en", { month: "long" });

export default function ExportModal() {
  const navigate = useNavigate();
  const { profileStore } = useStore();
  const { selectedYear } = profileStore;
  const [month, setMonth] = useState<number | undefined>();

  const { data: vatExportUrl } = useQuery({
    queryKey: ["vat-export", profileStore.selectedYear, month],
    queryFn: () => profileStore.exportVat(profileStore.selectedYear, month!),
    enabled: !!month,
    refetchOnWindowFocus: false,
  });

  const { mutate: exportAll, data: categoryExportUrl } = useMutation({
    mutationKey: ["category-export", selectedYear],
    mutationFn: profileStore.exportAllTransactions,
  });

  useEffect(() => {
    if (month && vatExportUrl) {
      const tempLink = document.createElement("a");
      tempLink.setAttribute("href", vatExportUrl as string);
      tempLink.setAttribute(
        "download",
        `vat-${profileStore.selectedYear}-${month}.xls`
      );
      tempLink.click();
      tempLink.remove();
      setMonth(undefined);
    }
  }, [vatExportUrl, setMonth, month, profileStore.selectedYear]);

  useEffect(() => {
    if (categoryExportUrl) {
      const tempLink = document.createElement("a");
      tempLink.setAttribute("href", categoryExportUrl as string);
      tempLink.setAttribute(
        "download",
        `all-export-${profileStore.selectedYear}.xls`
      );
      tempLink.click();
      tempLink.remove();
    }
  }, [categoryExportUrl, profileStore.selectedYear]);

  const months = useMemo(() => {
    const currentDate = new Date();
    // const currentMonth = currentDate.getMonth();
    const months = [];
    for (let i = 0; i < 12; i++) {
      const monthName = formatter.format(
        new Date(currentDate.getFullYear(), i, 1)
      );
      months.push({ month: i + 1, name: monthName });
    }
    return months;
  }, []);

  return (
    <Container>
      <ModalActionsWrapper onCancel={() => navigate(-1)}>
        <OptionContainer onClick={() => exportAll()}>
          All {selectedYear} by category
        </OptionContainer>
        {months.map((month) => {
          return (
            <OptionContainer
              onClick={() => setMonth(month.month)}
              key={`${selectedYear}-${month.month}`}
            >
              {month.name}
            </OptionContainer>
          );
        })}
      </ModalActionsWrapper>
    </Container>
  );
}

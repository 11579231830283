import { BiCheck, BiX } from "react-icons/bi";
import styled from "styled-components";
import * as yup from "yup";

import { formatPercentage } from "util/numbers";

import {
  DisplayInput,
  FieldContainer,
  FlexButton,
  FlexButtonContainer,
  TextInput,
  YesNoInput,
} from "components/ui/forms";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const validation = yup.object().shape({
  name: yup.string().required(),
});

const CategoryForm = ({
  data = {},
  onNameChange,
  onIsBusinessExpenseChange,
  onClaimablePercentageClick,
  onIsIncomeChange,
  onSubmit,
  onCancel,
}) => {
  const handleSubmit = () => {
    try {
      const validatedData = validation.validateSync(data, {
        abortEarly: false,
      });
      onSubmit(validatedData);
    } catch (errors) {
      console.log(errors);
      // setErrors(getKeyedValidationErrors(errors))
    }
  };

  const canSubmit = () => {
    try {
      validation.validateSync(data);
    } catch (errors) {
      return false;
    }
    return true;
  };

  const { name, isBusinessExpense, claimablePercentage, isIncome } = data;

  return (
    <Container>
      <div>
        <FieldContainer>
          <TextInput
            label="Name ..."
            placeholder="Name ..."
            value={name}
            onChange={onNameChange}
          />
        </FieldContainer>

        <FieldContainer>
          <YesNoInput
            label="Business Expense ?"
            value={isBusinessExpense}
            onChange={onIsBusinessExpenseChange}
          />
        </FieldContainer>

        {isBusinessExpense ? (
          <FieldContainer>
            <DisplayInput
              label="Claimable percentage: "
              placeholder=""
              onClick={onClaimablePercentageClick}
              value={claimablePercentage || 0}
              display={formatPercentage(claimablePercentage || 0)}
            />
          </FieldContainer>
        ) : null}

        <FieldContainer>
          <YesNoInput
            label="Income ?"
            value={isIncome}
            onChange={onIsIncomeChange}
          />
        </FieldContainer>
      </div>

      <FlexButtonContainer>
        <FlexButton onClick={onCancel} type="cancel">
          <BiX />
        </FlexButton>
        <FlexButton
          onClick={handleSubmit}
          disabled={!canSubmit()}
          type="accept"
        >
          <BiCheck />
        </FlexButton>
      </FlexButtonContainer>
    </Container>
  );
};

export default CategoryForm;

import React, { useState, useMemo } from "react";
import styled from "styled-components";
import z from "zod";

import { useStore } from "hooks";
import {
  TextInput,
  FlexButton,
  ButtonType,
  FieldContainer,
} from "components/ui/forms";

import { ButtonText } from "components/ui/Typography";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
const ContentContainer = styled.div`
  padding: 1rem;
  border-radius: 1rem;
  background-color: white;
  width: 300px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05);
`;
const TitleContainer = styled.div`
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;

const validation = z.object({
  name: z.string(),
});

const CreateProfile = () => {
  const { profileStore } = useStore();
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const setFieldValue = (field: string) => (value: any) => {
    setData({ ...data, [field]: value });
  };

  const disabled = useMemo(() => {
    try {
      validation.parse(data);
      return false;
    } catch (err) {
      return true;
    }
  }, [data]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const validatedData = validation.parse(data);
      profileStore.createProfile(validatedData.name).then(() => {
        navigate("/select-profile");
      });
    } catch (err) {
      // setErrors(getKeyedValidationErrors(validationError as ValidationError));
    }
  };

  return (
    <Container>
      <TitleContainer>Create Profile</TitleContainer>
      <ContentContainer>
        <form onSubmit={onSubmit}>
          <FieldContainer>
            <TextInput
              placeholder="profile name, eg: business, personal"
              onChange={setFieldValue("name")}
            />
          </FieldContainer>
          <FlexButton type={ButtonType.Submit} disabled={disabled}>
            <ButtonText>Create</ButtonText>
          </FlexButton>
        </form>
      </ContentContainer>
    </Container>
  );
};

export default CreateProfile;

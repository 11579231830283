import styled from "styled-components";
import { BiPlus, BiMinus } from "react-icons/bi";

import {
  DIRECTION_IN_BACKGROUND,
  DIRECTION_OUT_BACKGROUND,
  DIRECTION_DEFAULT_BACKGROUND,
} from "constants/colors";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => (props.disabled ? `opacity: 0.1;` : "")}
`;
const Option = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  font-size: 1.4rem;
  ${(props) =>
    props.selected
      ? `background-color: ${props.color};color: white;`
      : `background-color: ${DIRECTION_DEFAULT_BACKGROUND};color: black;`}
  &:first-of-type {
    border-radius: 5px 0 0 5px;
  }
  &:last-of-type {
    border-radius: 0 5px 5px 0;
  }
`;

export const InOutInput = ({ direction, onChange, disabled }) => {
  const handleChange = (newDirection) => () => {
    onChange && onChange(newDirection);
  };

  return (
    <>
      <Container disabled={disabled}>
        <Option
          onClick={handleChange("-")}
          selected={direction === "-"}
          color={DIRECTION_OUT_BACKGROUND}
        >
          <BiMinus />
        </Option>
        <Option
          onClick={handleChange("+")}
          selected={direction === "+"}
          color={DIRECTION_IN_BACKGROUND}
        >
          <BiPlus />
        </Option>
      </Container>
      <input type="hidden" value={direction} />
    </>
  );
};

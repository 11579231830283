import URI from "urijs";

const API_PREFIX = process.env.REACT_APP_API_HOST;

export class Api {
  authToken: string | null = null;
  errorHandler: Function = () => {};

  setErrorHandler(errorHandler: Function) {
    this.errorHandler = errorHandler;
  }

  fetchResponse(path: string, request?: any) {
    request.headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${this.authToken}`,
    };

    request.body =
      request.headers["Content-Type"] &&
      request.headers["Content-Type"].includes("multipart/form-data")
        ? request.body
        : JSON.stringify(request.body);

    return fetch(path, request).then(async (response) => {
      const result = {
        status: response.status,
        headers: response.headers,
        body: {} as any,
      };
      if (response.body) {
        result.body = await response.json();
        if (result.body.auth) {
          this.authToken = result.body.auth.token;
        }
      }
      if (response.status === 200 || response.status === 201) {
        return result;
      }
      throw result;
    });
  }

  uri() {
    return new URI(API_PREFIX);
  }

  get(path: string) {
    return this.fetchResponse(path, {});
  }

  post(path: string, data: any = {}) {
    return this.fetchResponse(path, { body: data, method: "POST" });
  }

  patch(path: string, data: any = {}) {
    return this.fetchResponse(path, { body: data, method: "PATCH" });
  }

  delete(path: string) {
    return this.fetchResponse(path, { method: "DELETE" });
  }

  setAuthToken(token: string) {
    this.authToken = token;
  }
}

const api = new Api();
export default api;

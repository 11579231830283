import { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "hooks";

import CategoryForm from "components/forms/category-form";
import Calculator from "components/panes/Calculator";
import PaneNavigator from "components/ui/pane-navigator";

// export default ({ onComplete, onCancel }) => {
//   const navigator = useRef()
//   const [data, setData] = useState({})

//   const { data: createResonse, isSuccess, mutate } = useMutation(createSubject)

//   useEffect(() => {
//     if (isSuccess) {
//       onComplete(createResonse)
//     }
//   }, [isSuccess])

//   const goBack = () => {
//     navigator.current.goBack()
//   }

//   const handleSubmit = (data) => {
//     mutate(data)
//   }

//   const handleBusinessExpenseChange = (value) => {
//     setData({ ...data, isBusinessExpense: value })
//   }

//   const handleNameChange = (value) => {
//     setData({ ...data, name: value })
//   }

//   const handleDefaultTaxChange = (tax) => {
//     setData({ ...data, defaultTax: tax })
//     goBack()
//   }

//   const handlePercentageClaimableChange = (tax) => {
//     setData({ ...data, percentageClaimable: tax })
//     goBack()
//   }

//   const openDefaultTax = () => {
//     navigator.current.navigateTo(
//       <Calculator
//         onAccept={handleDefaultTaxChange}
//         onCancel={goBack}
//         amount={data.tax}
//       />
//     )
//   }

//   const openPercentageClaimable = () => {
//     navigator.current.navigateTo(
//       <Calculator
//         onAccept={handlePercentageClaimableChange}
//         onCancel={goBack}
//         amount={data.tax}
//       />
//     )
//   }

//   return (
//     <PaneNavigator ref={(ref) => (navigator.current = ref)}>
//       <SubjectForm
//         onBusinessExpenseChange={handleBusinessExpenseChange}
//         onNameChange={handleNameChange}
//         data={data}
//         onDefaultTaxClick={openDefaultTax}
//         onPercentageTaxableClick={openPercentageClaimable}
//         onCancel={onCancel}
//         onSubmit={handleSubmit}
//       />
//     </PaneNavigator>
//   )
// }

export default function CreateCategory({ onComplete, onCancel }) {
  const { profileStore } = useStore();
  const navigator = useRef();
  const [data, setData] = useState({
    isBusinessExpense: false,
    claimablePercentage: 0,
    isIncome: false,
  });

  const {
    data: createResonse,
    isSuccess,
    mutate,
  } = useMutation({ mutationFn: profileStore.createCategory });

  useEffect(() => {
    if (isSuccess) {
      onComplete(createResonse);
    }
  }, [isSuccess, createResonse, onComplete]);

  const goBack = () => {
    navigator.current.goBack();
  };

  const handleSubmit = (data) => {
    mutate(data);
  };

  const handleNameChange = (value) => {
    setData({ ...data, name: value });
  };

  const handleChangeIsBusinessExpense = (value) => {
    setData({
      ...data,
      isBusinessExpense: value,
      isIncome: value ? false : data.isIncome,
    });
  };

  const handleChangeIsIncome = (value) => {
    setData({
      ...data,
      isIncome: value,
      isBusinessExpense: value ? false : data.isBusinessExpense,
    });
  };

  const handleClaimablePercentageChange = (value) => {
    setData({ ...data, claimablePercentage: value });
    goBack();
  };

  const openClaimablePercentage = () => {
    navigator.current.navigateTo(
      <Calculator
        onAccept={handleClaimablePercentageChange}
        onCancel={goBack}
        amount={data.claimablePercentage}
      />
    );
  };

  return (
    <PaneNavigator ref={navigator}>
      <CategoryForm
        onNameChange={handleNameChange}
        onIsBusinessExpenseChange={handleChangeIsBusinessExpense}
        data={data}
        onClaimablePercentageClick={openClaimablePercentage}
        onIsIncomeChange={handleChangeIsIncome}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </PaneNavigator>
  );
}

import { Navigate, Routes, Route } from "react-router-dom";
import { observer } from "mobx-react-lite";

import ProfileDashboard from "containers/interface/ProfileDashboard";
import { SelectProfile } from "containers/interface/SelectProfile";
import { useStore } from "hooks";
import CreateProfile from "./CreateProfile";

const Interface = () => {
  const { profileStore } = useStore();

  const renderRedirect = () => {
    if (!profileStore.profiles.length) {
      return <Navigate to="/create-profile" />;
    } else {
      if (!profileStore.selectedProfile) {
        return <Navigate to="/select-profile" />;
      }
      return <Navigate to={`/overview/${profileStore.selectedProfile}`} />;
    }
  };

  return (
    <>
      <Routes>
        <Route path="/select-profile" element={<SelectProfile />} />
        <Route path="/create-profile" element={<CreateProfile />} />
        <Route path="/overview/:profileId/*" element={<ProfileDashboard />} />
        <Route path="*" element={renderRedirect()} />
      </Routes>
    </>
  );
};

export default observer(Interface);

import styled from "styled-components";
import { hslToHex } from "util/color";

const Container = styled.div`
  border-radius: 5px;
  overflow: hidden;
`;
const ColorBlock = styled.div`
  display: inline-block;
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 0.7rem;
  margin: 0.1rem;
`;

const generateColors = (count: number) => {
  const hue = [0, 360];
  const lightness = [20, 80];
  const saturation = [20, 100];
  // const hueSteps = 24;

  const colors = [];

  for (let i = 0; i < count; i++) {
    const h = Math.floor(hue[0] + Math.random() * (hue[1] - hue[0]));
    const s = Math.floor(
      saturation[0] + Math.random() * (saturation[1] - saturation[0])
    );
    const l = Math.floor(
      lightness[0] + Math.random() * (lightness[1] - lightness[0])
    );
    colors.push(hslToHex(h, s, l));
  }

  return colors;
};

interface ColorGridProps {
  selectedColor: string;
  onChange: (hex: string) => void;
}

export const ColorGrid = ({ selectedColor, onChange }: ColorGridProps) => {
  const colors = generateColors(50);
  return (
    <Container>
      {selectedColor && (
        <ColorBlock
          key={selectedColor}
          style={{ backgroundColor: selectedColor }}
          onClick={() => onChange(selectedColor)}
        />
      )}
      {colors.map((hex) => (
        <ColorBlock
          key={hex}
          style={{ backgroundColor: hex }}
          onClick={() => onChange(hex)}
        />
      ))}
    </Container>
  );
};

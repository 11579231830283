import { Account } from "api/types/Account";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  min-width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
`;
const Indicator = styled.div`
  flex-grow: 1;
  ${(props) =>
    props.color ? `background-color:${props.color}` : `background-color: blue`}
`;

type ColorIndicatorProps = {
  account?: Account;
  fromAccount?: Account;
  toAccount?: Account;
};

export const ColorIndicator = ({
  account,
  fromAccount,
  toAccount,
}: ColorIndicatorProps) => {
  return (
    <Container>
      {account && <Indicator color={account.color} />}
      {fromAccount && <Indicator color={fromAccount.color} />}
      {toAccount && <Indicator color={toAccount.color} />}
    </Container>
  );
};

import React from "react";
import { createRoot } from "react-dom/client";
// import { ModalContainer } from 'react-router-modal'
// import 'react-router-modal/css/react-router-modal.css'
// import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createGlobalStyle } from "styled-components";

import App from "App";
import { MediaQueries } from "constants/responsive";
import { hslToHex } from "util/color";

const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
  }
  body {
    font-family: 'Roboto', sans-serif;
    min-height: 100vh;
    width: 100vw;
    background-color: ${hslToHex(180, 10, 90)};
    ${MediaQueries.Mobile}{
      font-size: 0.6rem;
    }
    &.modal-open{
      position: unset !important;
      & .app-container{
        filter: blur(10px);
      }
    }
  }
  * {
    box-sizing: border-box;
  }
  #root{
    min-height: 100%;
  }
  .app-container{
    min-height: 100vh;
  }
  .react-router-modal__modal {
    width: 600px;
  }
  @media screen and (max-width: 600px){
    .react-router-modal__modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: unset;
      background-color: rgba(255,255,255,0.95);
      border-radius: 0px;
      border: none;
    }
  }
`;

const queryClient = new QueryClient();

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <div className="app-container">
        <App />
      </div>
    </QueryClientProvider>
  </React.StrictMode>
);

//<ModalContainer />

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

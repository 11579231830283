import styled from "styled-components";
import Draggable from "react-draggable";
import { FiEdit2 } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";

import { ColorIndicator } from "./ColorIndicator";
import {
  Transaction,
  TransactionType,
  TransactionWithTotal,
} from "api/types/Transaction";
import { useNavigate } from "react-router-dom";
import { TransactionSwipeThreshold } from "constants/UI";
import { MediaQueries } from "constants/responsive";
import { useRef } from "react";
import { hslToHex } from "util/color";
import { ItemRow } from "./ItemRow";

const Container = styled.div`
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eeeeee;
  &:last-of-type {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: 0px;
  }
  ${MediaQueries.Mobile} {
    font-size: 0.6rem;
  }
`;
const SwipeContainer = styled.div`
  position: relative;
  overflow: hidden;
`;
const TransactionContainer = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;
  background-color: white;
  border-radius: 0.5rem;
`;
const IndicatorContainer = styled.div`
  min-width: 1rem;
  margin-right: 0.5rem;
`;
const Column = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const Note = styled.div`
  display: table-caption;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-right: 0.5rem;
  text-align: left;
`;
const DraggedOptionsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    height: 100%;
    width: calc(${TransactionSwipeThreshold}px + 1rem);
    ${MediaQueries.Mobile} {
      font-size: 1rem;
    }
  }
`;
const DragDeleteContainer = styled.div`
  background-color: hsl(0 100% 80%);
  color: hsl(0 100% 30%);
  & svg {
    margin-left: 15px;
    width: 30px;
  }
`;
const DragEditContainer = styled.div`
  background-color: hsl(200 100% 80%);
  color: hsl(200 100% 30%);
  & svg {
    margin-right: 15px;
    width: 30px;
  }
`;
const InfoContainer = styled.div`
  display: table;
  flex-grow: 1;
`;
const DragHandleContainer = styled.div`
  width: 100%;
`;
const DragHandle = styled.div`
  margin: 0 auto;
  width: 8rem;
  height: 0.3rem;
  border-radius: 0.15rem;
  box-shadow: inset 0 1px 3px ${hslToHex(100, 0, 75)};
  margin-top: 0.5rem;
  cursor: grab;
`;

type TransactionItemProps = {
  transaction: TransactionWithTotal;
  onCategoryClick: (id: number | null) => void;
  onSubjectClick: (id: number | null) => void;
  onAccountClick: (id: number | null) => void;
  onDelete: (transaction: Transaction) => void;
};

export const TransactionItem = ({
  transaction,
  onCategoryClick,
  onAccountClick,
  onSubjectClick,
  onDelete,
}: TransactionItemProps) => {
  const nodeRef = useRef(null);
  const navigate = useNavigate();
  const {
    id,
    account,
    subject,
    category,
    fromAccount,
    toAccount,
    type,
    amount,
    fromAmount,
    toAmount,
    vat,
  } = transaction.transaction;

  const handleDragEnd = (e: any, d: any) => {
    if (d.lastX === -TransactionSwipeThreshold) {
      onDelete(transaction.transaction);
    }
    if (d.lastX === TransactionSwipeThreshold) {
      if (type === TransactionType.Transaction) {
        navigate(`edit/transaction/${id}`);
      }
      if (type === TransactionType.Transfer) {
        navigate(`edit/transfer/${id}`);
      }
    }
  };

  return (
    <Container>
      <SwipeContainer>
        <DraggedOptionsContainer>
          <DragEditContainer>
            <FiEdit2 />
          </DragEditContainer>
          <DragDeleteContainer>
            <AiOutlineDelete />
          </DragDeleteContainer>
        </DraggedOptionsContainer>
        <Draggable
          axis="x"
          bounds={{
            left: -TransactionSwipeThreshold,
            right: TransactionSwipeThreshold,
          }}
          onStop={handleDragEnd}
          position={{ x: 0, y: 0 }}
          nodeRef={nodeRef}
          handle=".handle"
        >
          <TransactionContainer ref={nodeRef}>
            <IndicatorContainer>
              <ColorIndicator
                account={account}
                fromAccount={fromAccount}
                toAccount={toAccount}
              />
            </IndicatorContainer>
            <Column>
              <InfoContainer>
                {type === "transaction" && (
                  <>
                    <ItemRow
                      title={account!.name}
                      onTitleClick={() => onAccountClick(account?.id || null)}
                      amount={amount}
                      vat={vat}
                      monthAmount={transaction.total.account.month}
                      yearAmount={transaction.total.account.year}
                    />
                    <ItemRow
                      title={category!.name}
                      onTitleClick={() => onCategoryClick(category?.id || null)}
                      monthAmount={transaction.total.category.month}
                      yearAmount={transaction.total.category.year}
                    />
                    <ItemRow
                      title={subject!.name}
                      onTitleClick={() => onSubjectClick(subject?.id || null)}
                      monthAmount={transaction.total.subject.month}
                      yearAmount={transaction.total.subject.year}
                    />
                  </>
                )}
                {type === "transfer" && (
                  <>
                    <ItemRow
                      title={fromAccount!.name}
                      onTitleClick={() =>
                        onAccountClick(fromAccount?.id || null)
                      }
                      amount={fromAmount}
                      monthAmount={transaction.total.fromAccount.month}
                      yearAmount={transaction.total.fromAccount.year}
                    />
                    <ItemRow
                      title={toAccount!.name}
                      amount={toAmount}
                      onTitleClick={() => onAccountClick(toAccount?.id || null)}
                      monthAmount={transaction.total.toAccount.month}
                      yearAmount={transaction.total.toAccount.year}
                    />
                  </>
                )}
                {transaction.transaction.note && (
                  <Note>{transaction.transaction.note}</Note>
                )}
              </InfoContainer>
              <DragHandleContainer>
                <DragHandle className="handle" />
              </DragHandleContainer>
            </Column>
          </TransactionContainer>
        </Draggable>
      </SwipeContainer>
    </Container>
  );
};

import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes } from "react-router-dom";

import { useStore } from "hooks";
import Join from "./Join";
import Login from "./Login";

type AuthContainerProps = {
  children: JSX.Element;
};

export default observer(({ children }: AuthContainerProps) => {
  const { authStore } = useStore();

  if (authStore.isAuthenticated()) {
    return children;
  }

  return (
    <>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="join" element={<Join />} />
        <Route path="*" element={<Navigate to="login" replace />} />
      </Routes>
    </>
  );
});

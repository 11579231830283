import { useMemo, useRef } from "react";

import { useStore } from "hooks";

import { ItemSelector } from "components/panes/ItemSelector";
import { ModalActionsWrapper } from "components/ui/layout";
import PaneNavigator from "components/ui/pane-navigator";
import CreateAccount from "./create-account";

export default function SelectAccount({ onChange, onCancel }) {
  const { profileStore } = useStore();
  const { accounts } = profileStore;
  const navigator = useRef();

  const goBack = () => {
    navigator.current.goBack();
  };

  const handleCreateAccount = (account) => {
    onChange(account);
  };

  const openCreateAccount = () => {
    navigator.current.navigateTo(
      <CreateAccount
        profileId={profileStore.selectedProfile}
        onCancel={goBack}
        onComplete={handleCreateAccount}
      />
    );
  };

  const accountsList = useMemo(() => {
    if (accounts) {
      return accounts; //Object.keys(accounts).map((accountId) => accounts[accountId]);
    }
    return [];
  }, [accounts]);

  return (
    <PaneNavigator ref={(ref) => (navigator.current = ref)}>
      <ModalActionsWrapper onCreate={openCreateAccount} onCancel={onCancel}>
        <ItemSelector onChange={onChange} items={accountsList} />
      </ModalActionsWrapper>
    </PaneNavigator>
  );
}

import { MediaQueries } from "constants/responsive";
import styled from "styled-components";

export const HeaderSmall = styled.h3`
  font-size: 1.4rem;
  ${MediaQueries.Mobile} {
    font-size: 1rem;
  }
`;

export const NormalText = styled.span`
  font-size: 1rem;
  color: #333;
`;

export const ButtonText = styled.span`
  font-size: 1rem;
  color: #fff;
`;

import { useStore } from "hooks";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
const ContentContainer = styled.div`
  padding: 1rem;
  border-radius: 1rem;
  background-color: white;
  width: 300px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05);
`;
const TitleContainer = styled.div`
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;
const LinksContainer = styled.div`
  margin-top: 1rem;
`;
const Link = styled.span`
  cursor: pointer;
`;
const ProfileOption = styled.div`
  text-align: center;
  cursor: pointer;
  padding: 0.5rem;
  &:hover {
    background-color: lightgrey;
  }
`;

export const SelectProfile = () => {
  const navigate = useNavigate();
  const { profileStore, authStore } = useStore();
  const { profiles } = profileStore;

  const handleClick = (id: string) => () => {
    profileStore.setSelectedProfile(id);
    navigate(`/overview/${id}`);
  };

  const handleSignout = () => {
    authStore.signOut();
  };

  return (
    <Container>
      <TitleContainer>Select Profile</TitleContainer>
      <ContentContainer>
        {profiles.map((profile) => (
          <ProfileOption
            key={profile.id}
            onClick={handleClick(profile.id.toString())}
          >
            {profile.name}
          </ProfileOption>
        ))}
      </ContentContainer>
      <LinksContainer>
        <Link onClick={handleSignout}>Sign out</Link>
      </LinksContainer>
    </Container>
  );
};

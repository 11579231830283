import { AlertButton, Alert as AlertType } from "api/types/Alert";
import { BiCheck, BiX } from "react-icons/bi";
import styled from "styled-components";

import {
  ButtonType,
  FlexButton,
  FlexButtonContainer,
} from "components/ui/forms";
import { useStore } from "hooks";
import { HeaderSmall, NormalText } from "./Typography";
import Modal from "./modal";

const Content = styled.div`
  padding-bottom: 2rem;
  ${HeaderSmall} {
    margin: 0;
  }
  ${NormalText} {
    margin-top: 1rem;
  }
`;

type AlertProps = {
  id: string;
  alert: AlertType;
};

export const Alert = ({ id, alert }: AlertProps) => {
  const { alertStore } = useStore();

  const handleClick = (button: AlertButton) => {
    button.onClick && button.onClick();
    alertStore.dismiss(id);
  };

  return (
    <Modal width={400}>
      {(alert.title || alert.text) && (
        <Content>
          {alert.title && <HeaderSmall>{alert.title}</HeaderSmall>}
          {alert.text && <NormalText>{alert.text}</NormalText>}
        </Content>
      )}
      {alert.buttons && alert.buttons.length && (
        <FlexButtonContainer>
          {alert.buttons.map((button, index) => {
            return (
              <FlexButton
                key={`${id}-${index}`}
                type={button.type}
                onClick={() => handleClick(button)}
              >
                {button.text ? (
                  button.text
                ) : button.type === ButtonType.Accept ? (
                  <BiCheck />
                ) : (
                  <BiX />
                )}
              </FlexButton>
            );
          })}
        </FlexButtonContainer>
      )}
    </Modal>
  );
};

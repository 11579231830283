import { useRef } from "react";
import { useQuery } from "@tanstack/react-query";

import { useStore } from "hooks";

import { ItemSelector } from "components/panes/ItemSelector";
import { ModalActionsWrapper } from "components/ui/layout";
import PaneNavigator from "components/ui/pane-navigator";
import CreateProject from "./create-project";

export default function SelectProject({ onChange, onCancel }) {
  const { profileStore } = useStore();
  const navigator = useRef();
  const { data: projects, isLoading } = useQuery({
    queryKey: ["projects", profileStore.selectedProfile],
    queryFn: () => profileStore.getProjects(profileStore.selectedProfile),
  });

  const goBack = () => {
    navigator.current.goBack();
  };

  const handleCreateProject = (project) => {
    onChange(project);
  };

  const openCreateProject = () => {
    navigator.current.navigateTo(
      <CreateProject
        profileId={profileStore.selectedProfile}
        onCancel={goBack}
        onComplete={handleCreateProject}
      />
    );
  };

  if (isLoading) {
    return "loading...";
  }

  return (
    <PaneNavigator ref={navigator}>
      <ModalActionsWrapper onCreate={openCreateProject} onCancel={onCancel}>
        <ItemSelector onChange={onChange} items={projects || []} showIndex />
      </ModalActionsWrapper>
    </PaneNavigator>
  );
}

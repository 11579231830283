import styled from "styled-components";
import { enGB } from "date-fns/locale";
import { DatePickerCalendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const DateSelector = ({ onChange, date }) => {
  const handleChange = (dateObject) => {
    onChange(dateObject);
  };

  return (
    <Container>
      <DatePickerCalendar
        date={date}
        onDateChange={handleChange}
        locale={enGB}
      />
    </Container>
  );
};

export default DateSelector;

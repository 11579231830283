import { useRef, useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { useStore } from "hooks";

import { dateObjectToString } from "util/date";

import PaneNavigator from "components/ui/pane-navigator";
import TransferForm from "components/forms/transfer-form";
import DateSelector from "components/panes/date-selector";
import Calculator from "components/panes/Calculator";
import SelectAccount from "../select-account";

// interface Props {
//   onComplete: (data: any) => void;
//   onCancel: () => void;
// }

export default function AddTransfer({ onComplete, onCancel }) {
  const { date, id } = useParams();
  const { profileStore } = useStore();

  const [data, setData] = useState({
    transferId: id ? id : undefined,
    date: date ? new Date(date) : new Date(),
    amount: 0,
  });
  const navigator = useRef();

  const {
    mutate,
    data: createResponse,
    isSuccess,
  } = useMutation({ mutationFn: profileStore.createTransfer });

  useEffect(() => {
    if (id) {
      const transaction = profileStore.getTransaction(parseInt(id));
      if (transaction) {
        return setData({
          ...transaction,
          transferId: transaction.transaction_id?.toString(),
          date: new Date(transaction.date),
          amount: transaction.toAmount ?? 0,
        });
      }
      onCancel();
    }
  }, [id, onCancel, profileStore]);

  const goBack = () => {
    /** @ts-ignore */
    navigator.current.goBack();
  };

  const handleSubmit = (data) => {
    mutate({
      id: id ? parseInt(id) : undefined,
      transferId: data.transferId,
      fromAccount: data.fromAccount.id,
      toAccount: data.toAccount.id,
      amount: data.amount,
      date: dateObjectToString(data.date),
      note: data.note,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      onComplete(createResponse);
    }
  }, [isSuccess, createResponse, onComplete]);

  const handleDateChange = (date) => {
    setData({ ...data, date: date });
    navigator.current.goBack();
  };

  const handleFromAccountChange = (account) => {
    setData({ ...data, fromAccount: account });
    navigator.current.goBack();
  };

  const handleToAccountChange = (account) => {
    setData({ ...data, toAccount: account });
    navigator.current.goBack();
  };

  const handleAmountChange = (amount) => {
    setData({ ...data, amount: data.amount < 0 ? -amount : amount });
    navigator.current.goBack();
  };

  const openCalendar = () => {
    navigator.current.navigateTo(
      <DateSelector onChange={handleDateChange} date={data.date} />
    );
  };

  const openSelectFromAccount = () => {
    navigator.current.navigateTo(
      <SelectAccount
        onChange={handleFromAccountChange}
        onCancel={goBack}
        selected={data.fromAccount}
      />
    );
  };

  const openSelectToAccount = () => {
    navigator.current.navigateTo(
      <SelectAccount
        onChange={handleToAccountChange}
        onCancel={goBack}
        selected={data.toAccount}
      />
    );
  };

  const openAmount = () => {
    navigator.current.navigateTo(
      <Calculator
        onAccept={handleAmountChange}
        onCancel={navigator.current.goBack}
        amount={data.amount}
      />
    );
  };

  return (
    <PaneNavigator ref={navigator}>
      <TransferForm
        data={data}
        onDateClick={openCalendar}
        onFromAccountClick={openSelectFromAccount}
        onToAccountClick={openSelectToAccount}
        onAmountClick={openAmount}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      />
    </PaneNavigator>
  );
}

import styled from "styled-components";

import {
  ACCEPT_BUTTON_BACKGROUND,
  CANCEL_BUTTON_BACKGROUND,
  SUBMIT_BUTTON_BACKGROUND,
  SUBMIT_BUTTON_BACKGROUND_DISABLED,
} from "constants/colors";

const StyledButton = styled.button<{
  backgroundColor: string;
  disabled?: boolean;
}>`
  border: 0;
  flex-grow: 1;
  width: 100%;
  font-size: 1.4rem;
  display: flex;
  height: 4rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  ${(props) =>
    props.backgroundColor ? `background-color: ${props.backgroundColor};` : ""}
  ${(props) =>
    props.disabled ? `color: rgba(255,255,255,0.5);` : `color: white;`}
`;
export const FlexButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  cursor: pointer;
  & ${StyledButton}:first-of-type {
    border-radius: 0.5rem 0 0 0.5rem;
  }
  & ${StyledButton}:last-of-type {
    border-radius: 0 0.5rem 0.5rem 0;
  }
`;

export enum ButtonType {
  Create = "create",
  Accept = "accept",
  Submit = "submit",
  Cancel = "cancel",
}

function getBackgroundColor(type: ButtonType, disabled: boolean) {
  switch (type) {
    case "accept":
    case "create":
      return ACCEPT_BUTTON_BACKGROUND;
    case "cancel":
      return CANCEL_BUTTON_BACKGROUND;
    case "submit":
      return disabled
        ? SUBMIT_BUTTON_BACKGROUND_DISABLED
        : SUBMIT_BUTTON_BACKGROUND;
    default:
      return "grey";
  }
}

type FlexButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  children: JSX.Element | string;
  type: ButtonType;
};

export const FlexButton = ({
  onClick,
  disabled = false,
  children,
  type,
}: FlexButtonProps) => {
  const handleClick = () => {
    if (!disabled) {
      onClick && onClick();
    }
  };

  return (
    <StyledButton
      onClick={handleClick}
      disabled={disabled}
      backgroundColor={getBackgroundColor(type, disabled)}
      type={type === ButtonType.Submit ? "submit" : undefined}
    >
      {children}
    </StyledButton>
  );
};

import { BiCheck, BiX } from "react-icons/bi";
import styled from "styled-components";
import * as yup from "yup";

import { dateToPrettyString } from "util/date";
import { formatNumber } from "util/numbers";

import {
  DisplayInput,
  FieldContainer,
  FlexButton,
  FlexButtonContainer,
  TextInput,
} from "components/ui/forms";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;
const AccountFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;
const AccountFlexItem = styled.div`
  width: 100%;
  &:first-of-type {
    padding-right: 0.5rem;
  }
  &:last-of-type {
    padding-left: 0.5rem;
  }
`;

const validation = yup.object().shape({
  date: yup.date().required(),
  fromAccount: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string(),
    })
    .required(),
  toAccount: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string(),
    })
    .required(),
  amount: yup
    .number()
    .test((value) => {
      return Math.abs(value) > 0;
    })
    .required(),
});

const TransactionForm = ({
  data,
  onDateClick,
  onFromAccountClick,
  onToAccountClick,
  onAmountClick,
  onSubmit,
  onCancel,
}) => {
  const handleSubmit = () => {
    try {
      const validatedData = validation.validateSync(data, {
        abortEarly: false,
      });
      onSubmit(validatedData);
    } catch (errors) {
      console.log(errors);
      // setErrors(getKeyedValidationErrors(errors))
    }
  };

  const canSubmit = () => {
    try {
      validation.validateSync(data);
    } catch (errors) {
      return false;
    }
    return true;
  };

  const { date, fromAccount, toAccount, amount, note } = data;

  return (
    <Container>
      <div>
        <FieldContainer>
          <DisplayInput
            label="When ?"
            onClick={onDateClick}
            value={date}
            display={dateToPrettyString(date)}
          />
        </FieldContainer>

        <FieldContainer>
          <DisplayInput
            label="Amount ?"
            onClick={onAmountClick}
            value={amount || 0}
            display={formatNumber(Math.abs(amount) || 0)}
          />
        </FieldContainer>

        <FieldContainer>
          <AccountFlex>
            <AccountFlexItem>
              <DisplayInput
                placeholder="From ?"
                onClick={onFromAccountClick}
                value={fromAccount ? fromAccount.id : ""}
                display={fromAccount ? fromAccount.name : ""}
              />
            </AccountFlexItem>
            <AccountFlexItem>
              <DisplayInput
                placeholder="To ?"
                onClick={onToAccountClick}
                value={toAccount ? toAccount.id : ""}
                display={toAccount ? toAccount.name : ""}
              />
            </AccountFlexItem>
          </AccountFlex>
        </FieldContainer>

        <FieldContainer>
          <TextInput placeholder="Note ?" defaultValue={note} />
        </FieldContainer>
      </div>

      <FlexButtonContainer>
        <FlexButton onClick={onCancel} type="cancel">
          <BiX />
        </FlexButton>
        <FlexButton
          onClick={handleSubmit}
          disabled={!canSubmit()}
          type="accept"
        >
          <BiCheck />
        </FlexButton>
      </FlexButtonContainer>
    </Container>
  );
};

export default TransactionForm;

import api from "./api";
import { Category } from "./types/Category";

export function getCategories(profileId: string) {
  return Promise.resolve({
    frequentlyUsed: [
      {
        name: "Netto",
        id: 1,
        defaultVat: 0,
        lastUsedCategory: { name: "Groceries", id: 1 },
      },
      {
        name: "Lush",
        id: 1,
        defaultVat: 0,
        lastUsedCategory: null,
      },
    ],
    categories: [
      {
        name: "1+1",
        id: 1,
        defaultVat: 0,
        lastUsedCategory: { name: "Telecomms", id: 1 },
      },
      {
        name: "Netto",
        id: 1,
        defaultVat: 0,
        lastUsedCategory: { name: "Groceries", id: 1 },
      },
      {
        name: "Lush",
        id: 1,
        defaultVat: 0,
        lastUsedCategory: null,
      },
    ],
  });
}

export async function createCategory(
  profileId: string,
  name: string,
  isBusinessExpense: boolean,
  percentageClaimable: number,
  isIncome: boolean
): Promise<Category> {
  const response = await api.post(
    api.uri().segment("profile").segment(profileId).segment("category").href(),
    { name, isBusinessExpense, percentageClaimable, isIncome }
  );
  const { body } = response;
  return body.category;
}

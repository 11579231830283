import styled from "styled-components";

import { INPUT_BACKGROUND, INPUT_FOREGROUND } from "constants/colors";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 3rem;
  cursor: pointer;
`;
const Label = styled.div`
  width: 30%;
  font-size: 0.6rem;
  padding: 0 1rem;
`;
const Display = styled.div`
  height: 3rem;
  padding: 0px 1rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${INPUT_BACKGROUND};
  color: ${INPUT_FOREGROUND};
  border-radius: 5px;
`;

export const DisplayInput = ({
  value,
  name,
  label,
  placeholder,
  display,
  onClick,
}) => {
  return (
    <Container onClick={onClick || null}>
      {label && <Label>{label}</Label>}
      <Display>{display || placeholder}</Display>
      <input type="hidden" value={value} name={name} />
    </Container>
  );
};

import { MediaQueries } from "constants/responsive";
import { BiExport, BiMenu, BiPlus, BiTransferAlt } from "react-icons/bi";
import { HiCollection } from "react-icons/hi";
import { IoMdStats } from "react-icons/io";
import styled from "styled-components";

export enum IconSize {
  Medium = "medium",
}

export enum IconType {
  Menu = "menu",
  Transfer = "transfer",
  Plus = "plus",
  Export = "export",
  Stats = "stats",
  Collection = "collection",
}

const Container = styled.div<{ size: IconSize | undefined }>`
  ${(props) => {
    switch (props.size) {
      case IconSize.Medium:
        return `
          height: 40px; 
          width: 40px; 
          font-size: 1.4rem;
          ${MediaQueries.Mobile}{
            width: 30px;
            height: 30px;
            font-size: 1rem;
          }
          `;
      default:
        return "height: 30px; width: 30px; font-size: 1rem;";
    }
  }}
  color: black;
  border-radius: 0.5rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function getIcon(icon: IconType) {
  switch (icon) {
    case "menu":
      return <BiMenu />;
    case "transfer":
      return <BiTransferAlt />;
    case "plus":
      return <BiPlus />;
    case "export":
      return <BiExport />;
    case IconType.Stats:
      return <IoMdStats />;
    case IconType.Collection:
      return <HiCollection />;
    default:
      return null;
  }
}

type IconButtonProps = {
  icon: IconType;
  size?: IconSize;
  onClick?: () => void;
};

export const IconButton = ({ icon, size, onClick }: IconButtonProps) => {
  return (
    <Container size={size} onClick={onClick}>
      {getIcon(icon)}
    </Container>
  );
};

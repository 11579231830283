import { makeAutoObservable, runInAction, toJS } from "mobx";
import merge from "lodash.merge";

import { RootStore } from "./RootStore";
import profileApi from "../api/profile";
import { createSubject } from "../api/subjects";
import { Profile, Subject } from "api/types";
import {
  Transaction,
  TransactionFilter,
  TransactionFilters,
} from "api/types/Transaction";
import { createCategory } from "api/categories";
import { createProject } from "api/projects";
import { createAccount } from "api/accounts";
import {
  createTransaction,
  createTransfer,
  saveTransaction,
  saveTransfer,
  deleteTransaction,
  exportVat,
} from "api/transactions";
import { createProfile } from "api/profile";
import { Account } from "api/types/Account";
import { BalancesObject } from "api/types/Balances";
import { Category } from "api/types/Category";
import { Project } from "api/types/Project";
import { generateTransactionReport, generateVatReport } from "util/excel";
import { sortBy } from "util/data";

type DayTransactions = { [key: string]: Transaction[] };

export enum ProfileStoreEvents {
  ProfilesUpdted = "profiles-updated",
}

export class ProfileStore {
  public profiles: Profile[] = [];
  public selectedProfile: string | null = null;
  public selectedYear: number = new Date().getFullYear();
  public transactions: { [date: string]: Transaction[] } = {};
  public transactionsList: Transaction[] = [];
  public transactionFilters: TransactionFilters = {
    categoryId: null,
    accountId: null,
    subjectId: null,
    projectId: null,
  };
  public accounts: Account[] = [];
  public categories: { all: Category[]; frequentlyUsed: Category[] } = {
    all: [],
    frequentlyUsed: [],
  };
  public subjects: { all: Subject[]; frequentlyUsed: Subject[] } = {
    all: [],
    frequentlyUsed: [],
  };
  public projects: { all: Project[]; frequentlyUsed: Project[] } = {
    all: [],
    frequentlyUsed: [],
  };
  public balances: BalancesObject = {
    accounts: {},
    categories: {},
    subjects: {},
    projects: {},
  };

  constructor(rootStore: RootStore, profiles?: Profile[]) {
    this.profiles = profiles || [];

    rootStore.addEventListener(
      ProfileStoreEvents.ProfilesUpdted,
      this.updateProfiles.bind(this)
    );
    makeAutoObservable(this, {}, { autoBind: true });
  }

  updateProfiles(profiles: Profile[]) {
    this.profiles = profiles;
  }

  updateBalances(balances: BalancesObject) {
    runInAction(() => {
      this.balances = merge(this.balances, balances);
    });
  }

  addTransaction(transaction: Transaction) {
    runInAction(() => {
      if (!(transaction.date in this.transactions)) {
        this.transactions[transaction.date] = [];
      }
      this.transactions[transaction.date].unshift(transaction);
      const sortedTransactiions: { [key: string]: Transaction[] } = {};
      this.transactions = Object.keys(this.transactions)
        .sort()
        .reverse()
        .reduce((obj, key) => {
          obj[key] = this.transactions[key];
          return obj;
        }, sortedTransactiions);

      this.transactionsList = [];
      Object.keys(this.transactions).forEach((date) => {
        this.transactionsList.push(...this.transactions[date]);
      });
    });
  }

  removeTransaction = (id: number) => {
    const oldTransaction = this.getTransaction(id);
    if (oldTransaction) {
      runInAction(() => {
        this.transactionsList = this.transactionsList.filter(
          (transaction) => transaction.id !== id
        );

        this.transactions[oldTransaction.date] = this.transactions[
          oldTransaction.date
        ].filter((transaction) => transaction.id !== oldTransaction.id);
        if (!this.transactions[oldTransaction.date].length) {
          delete this.transactions[oldTransaction.date];
        }
      });
    }
  };

  getProfiles() {
    this.profiles = [];
  }

  setSelectedProfile(id: string) {
    this.selectedProfile = id;
  }

  setSelectedYear(year: number) {
    this.selectedYear = year;
  }

  setTransactionFilter(filter: TransactionFilter, value: number | null) {
    runInAction(() => {
      this.transactionFilters[filter] = value;
    });
  }

  getBalances = async () => {
    const balances = await profileApi.getBalances(
      this.selectedProfile as string,
      this.selectedYear
    );
    runInAction(() => {
      this.balances = balances;
    });
    return balances;
  };

  getSubjectById(id: number) {
    return this.subjects.all.find((subject) => subject.id === id);
  }

  async getSubjects() {
    const { subjects, frequentlyUsed } = await profileApi.getSubjects(
      this.selectedProfile!
    );
    runInAction(() => {
      this.subjects = { all: subjects, frequentlyUsed: frequentlyUsed };
    });
    return subjects;
  }

  getProjectById(id: number) {
    return this.projects.all.find((project) => project.id === id);
  }

  async getProjects() {
    const { projects, frequentlyUsed } = await profileApi.getProjects(
      this.selectedProfile!
    );
    runInAction(() => {
      this.projects = { all: projects, frequentlyUsed: frequentlyUsed };
    });
    return projects;
  }

  getAccountById(id: number) {
    return this.accounts.find((account) => account.id === id);
  }

  getAccounts = async () => {
    const accounts = await profileApi.getAccounts(
      this.selectedProfile!,
      this.selectedYear
    );
    runInAction(() => {
      this.accounts = accounts;
    });
    return accounts;
  };

  getCategoryById(id: number) {
    return this.categories.all.find((category) => category.id === id);
  }

  async getCategories() {
    const { categories, frequentlyUsed } = await profileApi.getCategories(
      this.selectedProfile!
    );
    runInAction(() => {
      this.categories = { all: categories, frequentlyUsed: frequentlyUsed };
    });
    return categories;
  }

  getTransactions = () => {
    return profileApi
      .getDayTransactions(
        this.selectedProfile!,
        this.selectedYear,
        this.transactionFilters
      )
      .then(({ transactions }) => {
        this.transactionsList = transactions;
        const returnTransactions: DayTransactions = {};
        transactions.forEach((transaction) => {
          if (!(transaction.date in returnTransactions)) {
            returnTransactions[transaction.date] = [];
          }
          returnTransactions[transaction.date].push(transaction);
        });
        runInAction(() => {
          this.transactions = returnTransactions;
        });
        return { transactions: returnTransactions };
      });
  };

  addToSubjectFrequentlyUsed(subjectId: number) {
    const subject = this.getSubjectById(subjectId);
    if (subject) {
      this.subjects.frequentlyUsed = [
        subject,
        ...this.subjects.frequentlyUsed.slice(1),
      ];
    }
  }

  addToCategoryFrequentlyUsed(categoryId: number) {
    const category = this.getCategoryById(categoryId);
    if (category) {
      this.categories.frequentlyUsed = [
        category,
        ...this.categories.frequentlyUsed.slice(1),
      ];
    }
  }

  addToProjectFrequentlyUsed(projectId: number) {
    // not today
    // const project = this.getProjectById(projectId);
    // if (project) {
    //   this.projects.frequentlyUsed = [
    //     project,
    //     ...this.projects.frequentlyUsed.slice(1),
    //   ];
    // }
  }

  createAccount({
    name,
    color,
    initialBalance = 0,
  }: {
    name: string;
    color: string;
    initialBalance: number;
  }) {
    return createAccount(
      this.selectedProfile!,
      name,
      color,
      initialBalance
    ).then((account) => {
      runInAction(() => {
        this.accounts.push(account);
      });
      return account;
    });
  }

  createTransaction({
    id,
    account,
    amount = 0,
    category,
    date,
    subject,
    vat = 0,
    note,
    project,
  }: {
    id?: number;
    account: number;
    amount: number;
    category: number;
    date: string;
    subject: number;
    vat: number;
    note?: string;
    project?: number;
  }) {
    if (id) {
      return saveTransaction(
        id,
        this.selectedProfile!,
        account,
        amount,
        category,
        date,
        subject,
        vat,
        note,
        project
      ).then(({ transaction, balance }) => {
        this.removeTransaction(id);
        this.addTransaction(transaction);
        this.updateBalances(balance);
        this.addToCategoryFrequentlyUsed(category);
        project && this.addToProjectFrequentlyUsed(project);
        this.addToSubjectFrequentlyUsed(subject);
        return transaction;
      });
    }
    return createTransaction(
      this.selectedProfile!,
      account,
      amount,
      category,
      date,
      subject,
      vat,
      note,
      project
    ).then(({ transaction, balance }) => {
      this.addTransaction(transaction);
      this.updateBalances(balance);
      this.addToCategoryFrequentlyUsed(category);
      project && this.addToProjectFrequentlyUsed(project);
      this.addToSubjectFrequentlyUsed(subject);
      return transaction;
    });
  }

  getTransaction(transactionId: number) {
    const transaction = this.transactionsList.find((transaction) => {
      return transaction.id === transactionId;
    });
    return toJS(transaction);
  }

  createTransfer({
    id,
    transferId,
    fromAccount,
    toAccount,
    amount = 0,
    date,
    note,
  }: {
    id?: number;
    transferId: number;
    fromAccount: string;
    toAccount: string;
    amount: number;
    date: string;
    note?: string;
  }) {
    if (id) {
      return saveTransfer(
        id,
        transferId,
        this.selectedProfile!,
        fromAccount,
        toAccount,
        amount,
        date,
        note
      ).then(({ transaction, balance }) => {
        this.removeTransaction(id);
        this.addTransaction(transaction);
        this.updateBalances(balance);
        return transaction;
      });
    }
    return createTransfer(
      this.selectedProfile!,
      fromAccount,
      toAccount,
      amount,
      date,
      note
    ).then(({ transaction, balance }) => {
      this.addTransaction(transaction);
      this.updateBalances(balance);
      return transaction;
    });
  }

  createProject({ name, budget }: { name: string; budget: number }) {
    return createProject(this.selectedProfile!, name, budget).then(
      (project) => {
        runInAction(() => {
          this.projects.all.push(project);
          this.projects.all = sortBy<Project>(this.projects.all, "name");
        });
        return project;
      }
    );
  }

  createSubject = ({
    name,
    defaultVat = 0,
  }: {
    name: string;
    defaultVat: number;
  }) => {
    return createSubject(this.selectedProfile!, name, defaultVat).then(
      (subject) => {
        runInAction(() => {
          this.subjects.all.push(subject);
          this.subjects.all = sortBy<Subject>(this.subjects.all, "name");
        });
        return subject;
      }
    );
  };

  createCategory({
    name,
    isBusinessExpense,
    claimablePercentage = 0,
    isIncome,
  }: {
    name: string;
    isBusinessExpense: boolean;
    claimablePercentage: number;
    isIncome: boolean;
  }) {
    return createCategory(
      this.selectedProfile!,
      name,
      isBusinessExpense,
      claimablePercentage,
      isIncome
    ).then((category) => {
      runInAction(() => {
        this.categories.all.push(category);
      });
      return category;
    });
  }

  async createProfile(name: string) {
    const profile = await createProfile(name);
    runInAction(() => {
      this.profiles.push(profile);
    });
    return profile;
  }

  deleteTransaction(transaction: Transaction) {
    return deleteTransaction(this.selectedProfile!, transaction.id).then(() => {
      this.removeTransaction(transaction.id);
    });
  }

  async exportVat(year: number, month: number) {
    const transactions = await exportVat(this.selectedProfile!, year, month);
    return generateVatReport(transactions);
  }

  async exportAllTransactions() {
    return generateTransactionReport(this.selectedYear, this.transactionsList);
  }
}

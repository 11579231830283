import { BiCheck, BiX } from "react-icons/bi";
import styled from "styled-components";
import * as yup from "yup";

import { formatCurrency } from "util/numbers";

import {
  ColorSlider,
  DisplayInput,
  FieldContainer,
  FlexButton,
  FlexButtonContainer,
  TextInput,
} from "components/ui/forms";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const validation = yup.object().shape({
  name: yup.string().required(),
  color: yup.string().required(),
});

const AccountForm = ({
  data = {},
  onColorChange,
  onNameChange,
  onBalanceClick,
  onSubmit,
  onCancel,
}) => {
  const handleSubmit = () => {
    try {
      const validatedData = validation.validateSync(data, {
        abortEarly: false,
      });
      onSubmit(validatedData);
    } catch (errors) {
      console.log(errors);
      // setErrors(getKeyedValidationErrors(errors))
    }
  };

  const canSubmit = () => {
    try {
      validation.validateSync(data);
    } catch (errors) {
      return false;
    }
    return true;
  };

  const { name, color, initialBalance } = data;

  return (
    <Container>
      <div>
        <FieldContainer>
          <TextInput
            label="Name ..."
            placeholder="Name ..."
            value={name}
            onChange={onNameChange}
          />
        </FieldContainer>

        <FieldContainer>
          <DisplayInput
            label="Initial balance"
            placeholder=""
            onClick={onBalanceClick}
            value={initialBalance || 0}
            display={formatCurrency(initialBalance || 0)}
          />
        </FieldContainer>

        <FieldContainer>
          <ColorSlider
            label="Colour"
            placeholder=""
            onChange={onColorChange}
            value={color}
          />
        </FieldContainer>
      </div>

      <FlexButtonContainer>
        <FlexButton onClick={onCancel} type="cancel">
          <BiX />
        </FlexButton>
        <FlexButton
          onClick={handleSubmit}
          disabled={!canSubmit()}
          type="accept"
        >
          <BiCheck />
        </FlexButton>
      </FlexButtonContainer>
    </Container>
  );
};

export default AccountForm;

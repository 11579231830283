import { useMemo } from "react";
import * as yup from "yup";

import { ModalActionsWrapper } from "components/ui/layout/modal-actions-wrapper";
import { dateToPrettyString } from "util/date";
import { formatNumber, formatPercentage } from "util/numbers";

import {
  DisplayInput,
  FieldContainer,
  InOutInput,
  TextInput,
} from "components/ui/forms";

const validation = yup.object().shape({
  date: yup.date().required(),
  subject: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
    })
    .required(),
  category: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
    })
    .required(),
  account: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string(),
    })
    .required(),
  amount: yup
    .number()
    .test((value) => {
      return Math.abs(value) > 0;
    })
    .required(),
});

const TransactionForm = ({
  data,
  onDateClick,
  onSubjectClick,
  onCatgoryClick,
  onProjectClick,
  onAccountClick,
  onAmountClick,
  onTaxClick,
  onDirectionChange,
  onNoteChange,
  onSubmit,
  onCancel,
}) => {
  const handleSubmit = () => {
    try {
      const validatedData = validation.validateSync(data, {
        abortEarly: false,
      });
      onSubmit(validatedData);
    } catch (errors) {
      console.log(errors);
      // setErrors(getKeyedValidationErrors(errors))
    }
  };

  const canSubmit = useMemo(() => {
    try {
      validation.validateSync(data);
    } catch (errors) {
      return false;
    }
    return true;
  }, [data]);

  const { date, subject, category, project, account, amount, tax, note } = data;

  return (
    <ModalActionsWrapper
      onCancel={onCancel}
      onAccept={handleSubmit}
      acceptIsDisabled={!canSubmit}
    >
      <FieldContainer>
        <DisplayInput
          label="When ?"
          placeholder=""
          onClick={onDateClick}
          value={date}
          display={dateToPrettyString(date)}
        />
      </FieldContainer>

      <FieldContainer>
        <DisplayInput
          label="Where / Who ?"
          placeholder="Select ..."
          onClick={onSubjectClick}
          value={subject ? subject.id : ""}
          display={subject ? subject.name : ""}
        />
      </FieldContainer>

      <FieldContainer>
        <DisplayInput
          label="Category ?"
          placeholder="Select ..."
          onClick={onCatgoryClick}
          value={category ? category.id : ""}
          display={category ? category.name : ""}
        />
      </FieldContainer>

      <FieldContainer>
        <DisplayInput
          label="Account"
          placeholder="Select ..."
          onClick={onAccountClick}
          value={account ? account.id : ""}
          display={account ? account.name : ""}
        />
      </FieldContainer>

      <FieldContainer>
        <DisplayInput
          label="Project ?"
          placeholder="Select ..."
          onClick={onProjectClick}
          value={project ? project.id : ""}
          display={project ? project.name : ""}
        />
      </FieldContainer>

      <FieldContainer>
        <DisplayInput
          label="Amount ?"
          placeholder=""
          onClick={onAmountClick}
          value={amount || 0}
          display={formatNumber(Math.abs(amount) || 0)}
        />
      </FieldContainer>

      <FieldContainer>
        <DisplayInput
          label="Sales Tax ?"
          placeholder=""
          onClick={onTaxClick}
          value={tax || 0}
          display={formatPercentage(tax || 0)}
        />
      </FieldContainer>

      <FieldContainer>
        <TextInput
          placeholder="Note ?"
          defaultValue={note}
          onChange={onNoteChange}
        />
      </FieldContainer>

      <FieldContainer>
        <InOutInput
          onChange={onDirectionChange}
          direction={amount <= 0 ? "-" : "+"}
          disabled={!amount}
        />
      </FieldContainer>
    </ModalActionsWrapper>
  );
};

export default TransactionForm;

import { MediaQueries } from "constants/responsive";
import styled from "styled-components";
import { formatCurrency } from "util/numbers";

const Total = styled.div`
  background-color: rgb(240, 240, 240);
  width: 120px;
  padding: 0 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-align: right;
`;
const Vat = styled.span`
  color: grey;
`;
const Title = styled.div`
  & span {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 1rem;
  }
  ${MediaQueries.Mobile} {
    min-width: 120px;
  }
`;
const InfoRow = styled.div`
  display: table-row;
  ${Title} {
    display: table-cell;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  ${Total} {
    display: table-cell;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  &:last-of-type {
    border: 0;
  }
`;

type InfoRowProps = {
  title: string;
  onTitleClick: () => void;
  vat?: number;
  amount?: number;
  monthAmount: number;
  yearAmount: number;
};

export const ItemRow = ({
  title,
  vat,
  onTitleClick,
  amount,
  monthAmount,
  yearAmount,
}: InfoRowProps) => {
  return (
    <InfoRow>
      <Title>
        <span onClick={onTitleClick} onTouchStart={onTitleClick}>
          {title}
        </span>
        {vat && vat > 0 ? <Vat>({formatCurrency(vat)}%)</Vat> : null}
      </Title>
      <Total>{amount ? formatCurrency(amount) : ""}</Total>
      <Total>{formatCurrency(monthAmount)}</Total>
      <Total>{formatCurrency(yearAmount)}</Total>
    </InfoRow>
  );
};

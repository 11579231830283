import { TransactionFilter } from "api/types/Transaction";
import { MediaQueries } from "constants/responsive";
import { useStore } from "hooks";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { BiX } from "react-icons/bi";
import styled from "styled-components";

const Contianer = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* margin-bottom: 1rem;
  ${MediaQueries.Mobile} {
    margin-bottom: 0rem;
  } */
`;
const Filter = styled.div`
  background-color: white;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 0 1rem;
  border-radius: 0.5rem;
  margin: 0 0.5rem;
  svg {
    margin-left: 0.5rem;
    width: 20px;
    height: 20px;
    cursor: pointer;
    ${MediaQueries.Mobile} {
      width: 15px;
      height: 15px;
    }
  }
`;

export const Filters = observer(() => {
  const { profileStore } = useStore();
  const { transactionFilters } = profileStore;

  const account = useMemo(() => {
    if (transactionFilters.accountId) {
      return profileStore.getAccountById(transactionFilters.accountId);
    }
    return null;
  }, [transactionFilters.accountId, profileStore]);

  const category = useMemo(() => {
    if (transactionFilters.categoryId) {
      return profileStore.getCategoryById(transactionFilters.categoryId);
    }
    return null;
  }, [transactionFilters.categoryId, profileStore]);

  const subject = useMemo(() => {
    if (transactionFilters.subjectId) {
      return profileStore.getSubjectById(transactionFilters.subjectId);
    }
    return null;
  }, [transactionFilters.subjectId, profileStore]);

  const project = useMemo(() => {
    if (transactionFilters.projectId) {
      return profileStore.getProjectById(transactionFilters.projectId);
    }
    return null;
  }, [transactionFilters.projectId, profileStore]);

  return (
    <Contianer>
      {account && (
        <Filter
          onClick={() =>
            profileStore.setTransactionFilter(TransactionFilter.AccountId, null)
          }
        >
          Account: {account.name}
          <BiX />
        </Filter>
      )}
      {category && (
        <Filter
          onClick={() =>
            profileStore.setTransactionFilter(
              TransactionFilter.CategoryId,
              null
            )
          }
        >
          Category: {category.name}
          <BiX />
        </Filter>
      )}
      {subject && (
        <Filter
          onClick={() =>
            profileStore.setTransactionFilter(TransactionFilter.SubjectId, null)
          }
        >
          To/From: {subject.name}
          <BiX />
        </Filter>
      )}
      {project && (
        <Filter
          onClick={() =>
            profileStore.setTransactionFilter(TransactionFilter.ProjectId, null)
          }
        >
          Project: {project.name}
          <BiX />
        </Filter>
      )}
    </Contianer>
  );
});

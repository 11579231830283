import { makeAutoObservable } from "mobx";

import { RootStore } from "./RootStore";
import { User, Profile } from "api/types";

export enum UserStoreEvents {
  UserUpdated = "user-updated",
  ProfilesUpdted = "profiles-updated",
}

export class UserStore {
  private rootStore: RootStore;
  public user: User | null = null;
  public profiles: Profile[] = [];

  constructor(rootStore: RootStore, user?: User, profiles?: Profile[]) {
    this.rootStore = rootStore;
    this.user = user || null;
    this.profiles = profiles || [];
    this.rootStore.addEventListener(
      UserStoreEvents.UserUpdated,
      this.updateUser.bind(this)
    );
    makeAutoObservable(this);
  }

  updateUser(user: User) {
    this.user = user;
  }
}

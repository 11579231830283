import { Alert } from "components/ui/Alert";
import { useStore } from "hooks";
import { observer } from "mobx-react-lite";

export const Alerts = observer(() => {
  const { alertStore } = useStore();
  const { alerts } = alertStore;

  return (
    <>
      {Object.keys(alerts).map((alertId) => (
        <Alert key={alertId} id={alertId} alert={alerts[alertId]} />
      ))}
    </>
  );
});

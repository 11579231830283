import authApi from "api/auth";
import { makeAutoObservable, runInAction } from "mobx";

import { RootStore } from "state/RootStore";
import { UserStoreEvents } from "state/UserStore";
import { ProfileStoreEvents } from "state/ProfileStore";

export class AuthStore {
  private rootStore: RootStore;
  private authToken: string | null = null;

  constructor(rootStore: RootStore, token?: string) {
    this.rootStore = rootStore;
    this.authToken = token || null;
    makeAutoObservable(this);
  }

  async login(email: string, password: string) {
    try {
      const response = await authApi.login(email, password);

      const { user, profiles, auth } = response;
      runInAction(() => {
        this.authToken = auth.token;
      });
      this.rootStore.emit(UserStoreEvents.UserUpdated, user);
      this.rootStore.emit(ProfileStoreEvents.ProfilesUpdted, profiles);
      this.rootStore.persistSession(auth.token, user, profiles);
    } catch (err: any) {
      console.log(err);
      // if (err.body.errors) {
      // throw input errors
      // }
    }
  }

  async register(name: string, email: string, password: string) {
    try {
      const response = await authApi.register(name, email, password);

      const { user, auth } = response;
      runInAction(() => {
        this.authToken = auth.token;
      });
      this.rootStore.emit(UserStoreEvents.UserUpdated, user);
      this.rootStore.emit(ProfileStoreEvents.ProfilesUpdted, []);
      this.rootStore.persistSession(auth.token, user, []);
    } catch (err: any) {
      console.log(err);
      // if (err.body.errors) {
      // throw input errors
      // }
    }
  }

  signOut() {
    this.authToken = null;
    this.rootStore.cleanSession();
    this.rootStore.emit(UserStoreEvents.UserUpdated, null);
  }

  isAuthenticated() {
    return this.authToken !== null;
  }
}

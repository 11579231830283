import api from "./api";
import { Profile, User } from "./types";

export class AuthApi {
  login(
    email: string,
    password: string
  ): Promise<{ profiles: Profile[]; user: User; auth: { token: string } }> {
    return api
      .post(api.uri().segment("auth").segment("login").href(), {
        email,
        password,
      })
      .then((response) => {
        return response.body;
      });
  }

  register(
    name: string,
    email: string,
    password: string
  ): Promise<{ profiles: Profile[]; user: User; auth: { token: string } }> {
    return api
      .post(api.uri().segment("auth").segment("register").href(), {
        name,
        email,
        password,
      })
      .then((response) => {
        return response.body;
      });
  }

  signOut() {
    return api
      .get(api.uri().segment("auth").segment("logout").href())
      .then((response) => {
        return response.body;
      });
  }
}

const authApi = new AuthApi();
export default authApi;

import { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "hooks";

import AccountForm from "components/forms/account-form";
import Calculator from "components/panes/Calculator";
import PaneNavigator from "components/ui/pane-navigator";

export default function CreateAccount({ onComplete, onCancel }) {
  const { profileStore } = useStore();
  const navigator = useRef();
  const [data, setData] = useState({});

  const {
    data: createResonse,
    isSuccess,
    mutate,
  } = useMutation({ mutationFn: profileStore.createAccount });

  useEffect(() => {
    if (isSuccess) {
      onComplete(createResonse);
    }
  }, [isSuccess, onComplete, createResonse]);

  const goBack = () => {
    navigator.current.goBack();
  };

  const handleSubmit = (data) => {
    mutate(data);
  };

  const handleNameChange = (value) => {
    setData({ ...data, name: value });
  };

  const handleColorChange = (value) => {
    setData({ ...data, color: value });
  };

  const handleBalanceChange = (value) => {
    setData({ ...data, initialBalance: value });
    goBack();
  };

  const openBalance = () => {
    navigator.current.navigateTo(
      <Calculator
        onAccept={handleBalanceChange}
        onCancel={goBack}
        amount={data.balance}
      />
    );
  };

  return (
    <PaneNavigator ref={navigator}>
      <AccountForm
        onNameChange={handleNameChange}
        data={data}
        onColorChange={handleColorChange}
        onBalanceClick={openBalance}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </PaneNavigator>
  );
}

import { useRef } from "react";

import { useStore } from "hooks";

import { ItemSelector } from "components/panes/ItemSelector";
import { ModalActionsWrapper } from "components/ui/layout";
import PaneNavigator from "components/ui/pane-navigator";
import { PaneNavigatorHandle } from "components/ui/pane-navigator/pane-navigator";
import CreateSubject from "./create-subject";

type SelectSubjectProps = {
  onChange: (data: any) => void;
  onCancel: () => void;
};

export default function SelectSubject({
  onChange,
  onCancel,
}: SelectSubjectProps) {
  const { profileStore } = useStore();
  const { subjects } = profileStore;
  const navigator = useRef<PaneNavigatorHandle>(null);

  const goBack = () => {
    navigator.current?.goBack();
  };

  const handleCreateSubject = (createData: any) => {
    onChange(createData);
  };

  const openCreateSubject = () => {
    navigator.current?.navigateTo(
      <CreateSubject onCancel={goBack} onComplete={handleCreateSubject} />
    );
  };

  return (
    <PaneNavigator ref={navigator}>
      <ModalActionsWrapper
        onCreate={openCreateSubject}
        onCancel={onCancel}
        acceptIsDisabled={false}
      >
        <ItemSelector
          onChange={onChange}
          items={subjects.all || []}
          frequentlyUsed={subjects.frequentlyUsed || []}
          showIndex
        />
      </ModalActionsWrapper>
    </PaneNavigator>
  );
}

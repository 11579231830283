import styled from 'styled-components'
import { BiX, BiCheck } from 'react-icons/bi'

import {
  DIRECTION_IN_BACKGROUND,
  DIRECTION_OUT_BACKGROUND,
  DIRECTION_DEFAULT_BACKGROUND,
} from 'constants/colors'

// const Container = styled.div`
//   display: flex;
// flex-direction: row;
// align-items: center;
// justify-content: flex-start;
//   border-radius: 5px;
//   height: 3rem;
//   background-color: ${INPUT_BACKGROUND};
//   padding: 0px 1rem;
// `

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 3rem;
  ${(props) => (props.disabled ? `opacity: 0.1;` : '')}
`
const Label = styled.div`
  width: 30%;
  font-size: 0.6rem;
  padding: 0px 1rem;
`
const Options = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Option = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 3rem;
  font-size: 1.4rem;
  ${(props) =>
    props.selected
      ? `background-color: ${props.color};`
      : `background-color: ${DIRECTION_DEFAULT_BACKGROUND};`}
  &:first-of-type {
    border-radius: 5px 0 0 5px;
  }
  &:last-of-type {
    border-radius: 0 5px 5px 0;
  }
`

export const YesNoInput = ({ value, label, onChange, disabled }) => {
  const handleChange = (newValue) => () => {
    onChange && onChange(newValue)
  }

  return (
    <Container disabled={disabled}>
      {label && <Label>{label}</Label>}
      <Options>
        <Option
          onClick={handleChange(true)}
          selected={value === true}
          color={DIRECTION_IN_BACKGROUND}
        >
          <BiCheck />
        </Option>
        <Option
          onClick={handleChange(false)}
          selected={!value}
          color={DIRECTION_OUT_BACKGROUND}
        >
          <BiX />
        </Option>
      </Options>
      <input type="hidden" value={''} />
    </Container>
  )
}
